import React from "react";

import workwerimg from "../../assest/images/banner/Workwearr-min.webp";

const Workwear = () => {
  return (
    <div
      className="w-full h-[300px] bg-cover bg-center relative flex items-center justify-center"
      style={{
        backgroundImage: `url(${workwerimg})`,
      }}
    >
      {/* Overlay for 50% filter */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Centered text */}
      <h1 className="relative text-3xl text-white font-bold">Workwear</h1>
    </div>
  );
};

export default Workwear;
