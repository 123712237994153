import React from "react";
import Navdesktop from "./navbar_desktop/Navdesktop";
import Navbar from "./navbar_mobile/Navbar";

const Header = () => {
  return (
    <div>
      {/* Mobile Navbar will be shown for screen sizes below xl */}
      <div className="block xl:hidden">
        <Navbar />
      </div>

      {/* Desktop Navbar will be shown for screen sizes xl and above */}
      <div className="hidden xl:block">
        <Navdesktop />
      </div>
    </div>
  );
};

export default Header;
