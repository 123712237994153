import React from "react";

import footimg from "../../assest/images/banner/Footprotectionindustry-min.webp";

const Footprotection = () => {
  return (
    <div
      className="w-full h-[300px] bg-cover bg-center relative flex items-center justify-center"
      style={{
        backgroundImage: `url(${footimg})`,
      }}
    >
      {/* Overlay for 50% filter */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Centered text */}
      <h1 className="relative text-3xl text-white font-bold">
        Foot Protection
      </h1>
    </div>
  );
};

export default Footprotection;
