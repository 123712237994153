import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import InspirationPage from "./pages/InspirationPage";
import ServicesPage from "./pages/ServicesPage";
import CompanyPage from "./pages/CompanyPage";
import NewsPage from "./pages/NewsPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ShopCategoryPage from "./pages/ShopCategoryPage";
import NoPage from "./pages/NoPage";
import SearchBar from "./components/Searchbar.jsx";

// banner images
import handarm from "./components/Banner/HandArmProtection.jsx";
import Footprotection from "./components/Banner/Footprotection.jsx";
import Respiratoryprotection from "./components/Banner/Respiratoryprotection.jsx";
import Eyeprotection from "./components/Banner/Eyeprotection.jsx";
import Headprotection from "./components/Banner/Headprotection.jsx";
import Kidsselection from "./components/Banner/Kidsselection.jsx";
import Workwear from "./components/Banner/Workwear.jsx";
import Protectiveclothing from "./components/Banner/Protectiveclothing.jsx";
import Cart from "./pages/Cart.jsx";

// inspiration

import Catalogue from "./components/Inspiration/Catalogue.jsx";
import CategoryBrochures from "./components/Inspiration/Categorybrochures.jsx";
import Guidelines from "./components/Inspiration/Guidelines.jsx";
import ResistanceLists from "./components/Inspiration/ResistanceLists.jsx";

// company section

import Aboutus from "./components/company/Aboutus.jsx";
import Fieldservice from "./components/company/Fieldservice.jsx";

// information

import Datenschutz from "./components/Information/Datenschutz.jsx";

import PrivacyPolicy from "./components/footer_desktop/PrivacyPolicy.jsx";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="/products"
          element={<PrivateRoute>{/* <ProductPage /> */}</PrivateRoute>}
        />

        {/* Categories and subcategories === start  */}

        {/* Categories and subcategories */}
        <Route path="/:category/:subcategory?" element={<ShopCategoryPage />} />

        {/* Workwear Routes */}
        <Route
          path="/workwear"
          element={<ShopCategoryPage category="Workwear" banner={Workwear} />}
        />
        <Route
          path="/workwear/trousers"
          element={
            <ShopCategoryPage
              category="Workwear"
              subcategory="Trousers"
              banner={Workwear}
            />
          }
        />
        <Route
          path="/workwear/jackets"
          element={
            <ShopCategoryPage
              category="Workwear"
              subcategory="Jackets"
              banner={Workwear}
            />
          }
        />
        <Route
          path="/workwear/waistcoats"
          element={
            <ShopCategoryPage
              category="Workwear"
              subcategory="Waistcoats"
              banner={Workwear}
            />
          }
        />
        <Route
          path="/workwear/pullovers"
          element={
            <ShopCategoryPage
              category="Workwear"
              subcategory="Pullovers"
              banner={Workwear}
            />
          }
        />
        <Route
          path="/workwear/shirts"
          element={
            <ShopCategoryPage
              category="Workwear"
              subcategory="Shirts"
              banner={Workwear}
            />
          }
        />
        <Route
          path="/workwear/aprons"
          element={
            <ShopCategoryPage
              category="Workwear"
              subcategory="Aprons"
              banner={Workwear}
            />
          }
        />
        <Route
          path="/workwear/accessories"
          element={
            <ShopCategoryPage
              category="Workwear"
              subcategory="Accessories"
              banner={Workwear}
            />
          }
        />

        {/* Protective Clothing Routes */}
        <Route
          path="/protective-clothing"
          element={
            <ShopCategoryPage
              category="Protective Clothing"
              banner={Protectiveclothing}
            />
          }
        />
        <Route
          path="/protective-clothing/protective-aprons"
          element={
            <ShopCategoryPage
              category="Protective Clothing"
              subcategory="Protective Aprons"
              banner={Protectiveclothing}
            />
          }
        />
        <Route
          path="/protective-clothing/protective-overalls"
          element={
            <ShopCategoryPage
              category="Protective Clothing"
              subcategory="Protective Overalls"
              banner={Protectiveclothing}
            />
          }
        />
        <Route
          path="/protective-clothing/high-visibility-clothing"
          element={
            <ShopCategoryPage
              category="Protective Clothing"
              subcategory="High Visibility Clothing"
              banner={Protectiveclothing}
            />
          }
        />
        <Route
          path="/protective-clothing/accessories"
          element={
            <ShopCategoryPage
              category="Protective Clothing"
              subcategory="Accessories"
              banner={Protectiveclothing}
            />
          }
        />

        {/* Hand / Arm Protection Routes */}
        <Route
          path="/hand-arm-protection"
          element={
            <ShopCategoryPage category="Hand Arm Protection" banner={handarm} />
          }
        />
        <Route
          path="/hand-arm-protection/arm-protection"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Arm Protection"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/antistatic-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Antistatic Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/chemical-protective-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Chemical Protective Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/mechanics-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Mechanic's Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/cut-protection-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Cut Protection Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/welding-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Welding Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/puncture-protection-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Puncture Protection Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/winter-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Winter Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/leather-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Leather Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/nitrile-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Nitrile Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/knitted-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Knitted Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/pvc-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="PVC Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/cotton-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Cotton Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/disposable-gloves"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Disposable Gloves"
              banner={handarm}
            />
          }
        />
        <Route
          path="/hand-arm-protection/accessories"
          element={
            <ShopCategoryPage
              category="Hand Arm Protection"
              subcategory="Accessories"
              banner={handarm}
            />
          }
        />

        {/* Foot Protection Routes */}
        <Route
          path="/foot-protection"
          element={
            <ShopCategoryPage
              category="Foot Protection"
              banner={Footprotection}
            />
          }
        />
        <Route
          path="/foot-protection/clogs"
          element={
            <ShopCategoryPage
              category="Foot Protection"
              subcategory="Clogs"
              banner={Footprotection}
            />
          }
        />
        <Route
          path="/foot-protection/low-shoes"
          element={
            <ShopCategoryPage
              category="Foot Protection"
              subcategory="Low Shoes"
              banner={Footprotection}
            />
          }
        />
        <Route
          path="/foot-protection/sandals"
          element={
            <ShopCategoryPage
              category="Foot Protection"
              subcategory="Sandals"
              banner={Footprotection}
            />
          }
        />
        <Route
          path="/foot-protection/slippers"
          element={
            <ShopCategoryPage
              category="Foot Protection"
              subcategory="Slippers"
              banner={Footprotection}
            />
          }
        />
        <Route
          path="/foot-protection/boots"
          element={
            <ShopCategoryPage
              category="Foot Protection"
              subcategory="Boots"
              banner={Footprotection}
            />
          }
        />
        <Route
          path="/foot-protection/winter-boots"
          element={
            <ShopCategoryPage
              category="Foot Protection"
              subcategory="Winter Boots"
              banner={Footprotection}
            />
          }
        />
        <Route
          path="/foot-protection/accessories"
          element={
            <ShopCategoryPage
              category="Foot Protection"
              subcategory="Accessories"
              banner={Footprotection}
            />
          }
        />

        {/* Respiratory Protection Routes */}
        <Route
          path="/respiratory-protection"
          element={
            <ShopCategoryPage
              category="Respiratory Protection"
              banner={Respiratoryprotection}
            />
          }
        />
        <Route
          path="/respiratory-protection/respirator-masks"
          element={
            <ShopCategoryPage
              category="Respiratory Protection"
              subcategory="Respirator Masks"
              banner={Respiratoryprotection}
            />
          }
        />

        {/* Eye Protection Routes */}
        <Route
          path="/eye-protection"
          element={
            <ShopCategoryPage
              category="Eye Protection"
              banner={Eyeprotection}
            />
          }
        />
        <Route
          path="/eye-protection/ultraviolet-filter"
          element={
            <ShopCategoryPage
              category="Eye Protection"
              subcategory="Ultraviolet Filter"
              banner={Eyeprotection}
            />
          }
        />
        <Route
          path="/eye-protection/sunglare-filter"
          element={
            <ShopCategoryPage
              category="Eye Protection"
              subcategory="Sunglare Filter"
              banner={Eyeprotection}
            />
          }
        />
        <Route
          path="/eye-protection/accessories"
          element={
            <ShopCategoryPage
              category="Eye Protection"
              subcategory="Accessories"
              banner={Eyeprotection}
            />
          }
        />

        {/* Head Protection Routes */}
        <Route
          path="/head-protection"
          element={
            <ShopCategoryPage
              category="Head Protection"
              banner={Headprotection}
            />
          }
        />
        <Route
          path="/head-protection/safety-helmets"
          element={
            <ShopCategoryPage
              category="Head Protection"
              subcategory="Safety Helmets"
              banner={Headprotection}
            />
          }
        />
        <Route
          path="/head-protection/accessories"
          element={
            <ShopCategoryPage
              category="Head Protection"
              subcategory="Accessories"
              banner={Headprotection}
            />
          }
        />

        {/* Kids Selection Routes */}
        <Route
          path="/kids-selection"
          element={
            <ShopCategoryPage
              category="Kids Selection"
              banner={Kidsselection}
            />
          }
        />
        <Route
          path="/kids-selection/garments"
          element={
            <ShopCategoryPage
              category="Kids Selection"
              subcategory="Garments"
              banner={Kidsselection}
            />
          }
        />
        <Route
          path="/kids-selection/hand-protection"
          element={
            <ShopCategoryPage
              category="Kids Selection"
              subcategory="Hand Protection"
              banner={Kidsselection}
            />
          }
        />
        <Route
          path="/kids-selection/accessories"
          element={
            <ShopCategoryPage
              category="Kids Selection"
              subcategory="Accessories"
              banner={Kidsselection}
            />
          }
        />

        {/* Categories and subcategories === end  */}

        {/* NoPage route for invalid categories */}
        <Route path="/404" element={<NoPage />} />

        {/* Fallback for other invalid URLs */}
        <Route path="*" element={<NoPage />} />
        {/* search button ===> start */}
        <Route path="/searchbar" element={<SearchBar />} />
        {/* search button ===> end*/}

        <Route path="/product" element={<ProductPage />}>
          <Route path=":productId" element={<ProductPage />} />
        </Route>
        <Route path="/cart" element={<Cart />} />
        <Route path="/inspiration" element={<InspirationPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/company" element={<CompanyPage />} />
        {/* Inspiration */}
        <Route path="/catalogue" element={<Catalogue />} />
        <Route path="/category-brochures" element={<CategoryBrochures />} />
        <Route path="/guidelines" element={<Guidelines />} />
        <Route path="/resistance-lists" element={<ResistanceLists />} />
        {/* company */}
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/field-service" element={<Fieldservice />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/news" element={<NewsPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
