import React from "react";
import Item from "../Item/Item";
import { useContext } from "react";
import { Shopcontext } from "../../context/Shopcontext";

const RelatedProducts = () => {
  const { allProducts } = useContext(Shopcontext);

  return (
    <div className="relatedproducts mt-20 mb-20 mx-4 md:mx-20">
      <h1 className="text-black text-3xl md:text-5xl font-semibold text-center">
        Related Products
      </h1>
      <hr className="w-24 h-1 rounded bg-gray-800 mx-auto" />
      <div className="relatedproduct-item mt-10 flex flex-wrap justify-center gap-6">
        {allProducts.map((item, i) => (
          <Item key={i} id={item.id} name={item.name} image={item.images} />
        ))}
      </div>
    </div>
  );
};

export default RelatedProducts;
