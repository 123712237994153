import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Shopcontext } from "../../context/Shopcontext";
import { MdOutlineTranslate } from "react-icons/md";
import { useTranslation } from "react-i18next";

// import image from nav
import img01 from "../../assest/images/nav/img_01_navbar.webp";
import { ReactComponent as Logo01 } from "../../assest/slider/kompakt_logo_red.svg";
import germanflag from "../../assest/images/navbar_icon_lang/german.png";
import albanianflag from "../../assest/images/navbar_icon_lang/albania.png";
import usaflag from "../../assest/images/navbar_icon_lang/united-states.png";
// import Logo01 from "../assest/slider/kompakt_logo_red.svg";

// console.log(Logo01);

const Navdesktop = () => {
  const [visibleButton, setVisibleButton] = useState(null);
  const { getTotalCartItems } = useContext(Shopcontext);

  const { i18n, t } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    // Dynamically change the dropdown image
    const selectElement = document.getElementById("language-selector");
    if (lang === "en") {
      selectElement.style.backgroundImage = `url(${usaflag})`; // Corrected
    } else if (lang === "de") {
      selectElement.style.backgroundImage = `url(${germanflag})`; // Corrected
    } else if (lang === "sq") {
      selectElement.style.backgroundImage = `url(${albanianflag})`; // Corrected
    }
  };

  const handleVisibleButton = (buttonId) => {
    if (visibleButton === buttonId) {
      setVisibleButton(null);
    } else {
      setVisibleButton(buttonId);
    }
  };

  // Set the default language to German on page load
  useEffect(() => {
    const selectElement = document.getElementById("language-selector");
    selectElement.value = "de"; // Set the value to German by default
    selectElement.style.backgroundImage = `url(${germanflag})`; // Set the flag to German on page load
  }, []);

  const [menu, setMenu] = useState("shop");

  return (
    <>
      <header className="relative w-full h-[90px]  container-sm mx-auto px-4 py-6 flex items-center justify-between border border-x-0 border-t-0 border-b-red-500">
        <Link to="/" className="font-bold text-black text-xl">
          <Logo01 alt="logo" className="h-8 w-auto" />
        </Link>
        <nav>
          <ul className="absolute  left-0 top-[90px] xl:top-auto xl:left-auto z-50 xl:relative bg-white w-full flex flex-col xl:items-center xl:flex-row  xl:justify-center font-semibold">
            <li className="relative group px-3 py-2">
              <button
                className="hover:opacity-50 cursor-default "
                aria-haspopup="true"
              >
                {t("Product")}
              </button>
              <div className=" relative xl:absolute xl:left-1/2 xl:-translate-x-1/2 xl:top-3 xl:transition xl:group-hover:translate-y-5 xl:translate-y-0 xl:opacity-0 xl:invisible xl:group-hover:opacity-100 xl:group-hover:visible xl:duration-500 xl:ease-in-out xl:z-50 xl:min-w-[800px] xl:transform">
                <div className="relative xl:top-6 xl:p-6 xl:bg-white xl:rounded-xl xl:shadow-xl xl:w-full">
                  <div className="xl:w-10 xl:h-10 xl:bg-white xl:transform xl:rotate-45 xl:absolute xl:top-0 xl:z-0 xl:translate-x-0 xl:transition-transform xl:group-hover:translate-x-[12rem] xl:duration-500 xl:ease-in-out xl:rounded-sm" />
                  <div className="relative z-10">
                    <div className="grid xl:grid-cols-3 xl:gap-7">
                      <div>
                        <p className="uppercase tracking-wider font-bold text-red-500  text-[13px]">
                          {t("Service")}
                        </p>
                        <ul className="mt-3 text-[15px] grid ">
                          <li
                            className="order-1 "
                            onClick={() => {
                              handleVisibleButton(1);
                              setMenu("Workwear");
                            }}
                          >
                            <Link
                              to="/workwear"
                              className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100 transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                            >
                              {t("Workwear")}
                              <i className="pi pi-chevron-right text-xs"></i>
                            </Link>

                            {menu === "Workwear" ? <hr /> : null}
                          </li>
                          <li
                            className="order-9"
                            onClick={() => {
                              handleVisibleButton(2);
                              setMenu("Protective_clothing");
                            }}
                          >
                            <Link
                              to="/protective-clothing"
                              className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                            >
                              {t("Protective_clothing")}{" "}
                              <i className="pi pi-chevron-right text-xs"></i>
                            </Link>
                            {menu === "Protective clothing" ? <hr /> : null}
                          </li>
                          <li
                            className="order-14"
                            onClick={() => {
                              handleVisibleButton(3);
                              setMenu("Hand / arm protection");
                            }}
                          >
                            <Link
                              to="/hand-arm-protection"
                              className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                            >
                              {t("Hand_arm_protection")}
                              <i className="pi pi-chevron-right text-xs"></i>
                            </Link>
                            {menu === "Hand / arm protection" ? <hr /> : null}
                          </li>
                          <li
                            onClick={() => {
                              handleVisibleButton(4);
                              setMenu("Foot protection");
                            }}
                          >
                            <Link
                              to="/foot-protection"
                              className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                            >
                              {t("Foot_protection")}
                              <i className="pi pi-chevron-right text-xs"></i>
                            </Link>
                            {menu === "Foot protection" ? <hr /> : null}
                          </li>
                          <li
                            onClick={() => {
                              handleVisibleButton(5);
                              setMenu("Respiratory protection");
                            }}
                          >
                            <Link
                              to="/respiratory-protection"
                              className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                            >
                              {t("Respiratory_protection")}
                              <i className="pi pi-chevron-right text-xs"></i>
                            </Link>
                            {menu === "Respiratory protection" ? <hr /> : null}
                          </li>
                          <li
                            onClick={() => {
                              handleVisibleButton(6);
                              setMenu("Eye protection");
                            }}
                          >
                            <Link
                              to="/eye-protection"
                              className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                            >
                              {t("Eye_protection")}
                              <i className="pi pi-chevron-right text-xs"></i>
                              {menu === "Eye protection" ? <hr /> : null}
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              handleVisibleButton(7);
                              setMenu("Head protection");
                            }}
                          >
                            <Link
                              to="/head-protection"
                              className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                            >
                              {t("Head_protection")}
                              <i className="pi pi-chevron-right text-xs"></i>
                            </Link>
                            {menu === "Head protection" ? <hr /> : null}
                          </li>
                          <li
                            onClick={() => {
                              handleVisibleButton(8);
                              setMenu("Kids selection");
                            }}
                          >
                            <Link
                              to="/kids-selection"
                              className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                            >
                              {t("Kids_selection")}
                              <i className="pi pi-chevron-right text-xs"></i>
                            </Link>
                            {menu === "Kids selection" ? <hr /> : null}
                          </li>
                        </ul>
                      </div>

                      <div>
                        {visibleButton === 1 && (
                          <>
                            <p className="uppercase tracking-wider text-red-500 font-bold text-[13px] ">
                              {t("Workwear")}
                            </p>
                            <ul className="mt-4 text-[15px]">
                              <li
                                className="order-2 "
                                onClick={() => {
                                  setMenu("Trousers");
                                }}
                              >
                                <Link
                                  to="/workwear/trousers"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Trousers")}
                                </Link>
                                {menu === "Trousers" ? <hr /> : null}
                              </li>
                              <li
                                className="order-3"
                                onClick={() => {
                                  setMenu("Jackets");
                                }}
                              >
                                <Link
                                  to="/workwear/jackets"
                                  className=" block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Jackets")}
                                </Link>
                                {menu === "Jackets" ? <hr /> : null}
                              </li>
                              <li
                                className="order-4 "
                                onClick={() => {
                                  setMenu("Waistcoats");
                                }}
                              >
                                <Link
                                  to="/workwear/waistcoats"
                                  className=" block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Waistcoats")}
                                </Link>
                                {menu === "Waistcoats" ? <hr /> : null}
                              </li>
                              <li
                                className="order-5"
                                onClick={() => {
                                  setMenu("Pullovers");
                                }}
                              >
                                <Link
                                  to="/workwear/pullovers"
                                  className=" block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Pullovers")}
                                </Link>
                                {menu === "Pullovers" ? <hr /> : null}
                              </li>
                              <li
                                className="order-6"
                                onClick={() => {
                                  setMenu("Shirts");
                                }}
                              >
                                <Link
                                  to="/workwear/shirts"
                                  className=" block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Shirts")}
                                </Link>
                                {menu === "Shirts" ? <hr /> : null}
                              </li>
                              <li
                                className="order-7"
                                onClick={() => {
                                  setMenu("Aprons");
                                }}
                              >
                                <Link
                                  to="/workwear/aprons"
                                  className=" block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Aprons")}
                                </Link>
                                {menu === "Aprons" ? <hr /> : null}
                              </li>
                              <li
                                className="order-8"
                                onClick={() => {
                                  setMenu("Accessories");
                                }}
                              >
                                <Link
                                  to="/workwear/accessories"
                                  className=" block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1">
                                    {"  "}
                                  </i>
                                  {t("Accessories")}
                                </Link>
                                {menu === "Accessories" ? <hr /> : null}
                              </li>
                            </ul>
                          </>
                        )}
                        {visibleButton === 2 && (
                          <>
                            <p className="uppercase tracking-wider text-red-500 font-bold text-[13px]">
                              {t("Protective_clothing")}
                            </p>
                            <ul className="mt-4 text-[15px]">
                              <li
                                className="order-10"
                                onClick={() => {
                                  setMenu("Protective aprons");
                                }}
                              >
                                <Link
                                  to="/protective-clothing/protective-aprons"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Protective_aprons")}
                                </Link>
                                {menu === "Protective aprons" ? <hr /> : null}
                              </li>
                              <li
                                className="order-11"
                                onClick={() => {
                                  setMenu("Protective overalls");
                                }}
                              >
                                <Link
                                  to="/protective-clothing/protective-overalls"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Protective_overalls")}
                                </Link>
                                {menu === "Protective overalls" ? <hr /> : null}
                              </li>
                              <li
                                className="order-12"
                                onClick={() => {
                                  setMenu("High-visibility clothing");
                                }}
                              >
                                <Link
                                  to="/protective-clothing/high-visibility-clothing"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("High_visibility_clothing")}
                                </Link>
                                {menu === "High-visibility clothing" ? (
                                  <hr />
                                ) : null}
                              </li>
                              <li
                                className="order-13"
                                onClick={() => {
                                  setMenu("Accessories");
                                }}
                              >
                                <Link
                                  to="/protective-clothing/accessories"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Accessories")}
                                </Link>
                                {menu === "Accessories" ? <hr /> : null}
                              </li>
                            </ul>
                          </>
                        )}
                        {visibleButton === 3 && (
                          <>
                            <p className="uppercase tracking-wider text-red-500 font-bold text-[13px]">
                              {t("Hand_arm_protection")}
                            </p>
                            <ul className="mt-4 text-[15px]">
                              <li
                                className="order-15"
                                onClick={() => {
                                  setMenu("Arm protection");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/arm-protection"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Arm_protection")}
                                </Link>
                                {menu === "Arm protection" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Antistatic gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/antistatic-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Antistatic_gloves")}
                                </Link>
                                {menu === "Antistatic gloves" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Chemical protective gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/chemical-protective-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Chemical_protective_gloves")}
                                </Link>
                                {menu === "Chemical protective gloves" ? (
                                  <hr />
                                ) : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Mechanic's gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/mechanics-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Mechanic_s_gloves")}
                                </Link>
                                {menu === "Mechanic's gloves" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Cut protection gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/cut-protection-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Cut_protection_gloves")}
                                </Link>
                                {menu === "Cut protection gloves" ? (
                                  <hr />
                                ) : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Welding gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/welding-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Welding_gloves")}
                                </Link>
                                {menu === "Welding gloves" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Puncture protection gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/puncture-protection-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Puncture_protection_gloves")}
                                </Link>
                                {menu === "Puncture protection gloves" ? (
                                  <hr />
                                ) : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Winter gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/winter-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Winter_gloves")}
                                </Link>
                                {menu === "Winter gloves" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Leather gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/leather-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Leather_gloves")}
                                </Link>
                                {menu === "Leather gloves" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Nitrile gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/nitrile-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Nitrile_gloves")}
                                </Link>
                                {menu === "Nitrile gloves" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Knitted gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/knitted-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Knitted_gloves")}
                                </Link>
                                {menu === "Knitted gloves" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("PVC gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/pvc-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("PVC_gloves")}
                                </Link>
                                {menu === "PVC gloves" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Cotton gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/cotton-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Cotton_gloves")}
                                </Link>
                                {menu === "Cotton gloves" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Disposable gloves");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/disposable-gloves"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Disposable_gloves")}
                                </Link>
                                {menu === "Disposable gloves" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Accessories");
                                }}
                              >
                                <Link
                                  to="/hand-arm-protection/accessories"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Accessories")}
                                </Link>
                                {menu === "Accessories" ? <hr /> : null}
                              </li>
                            </ul>
                          </>
                        )}
                        {visibleButton === 4 && (
                          <>
                            <p className="uppercase tracking-wider text-red-500 font-bold text-[13px]">
                              {t("Foot_protection")}
                            </p>
                            <ul className="mt-4 text-[15px]">
                              <li
                                onClick={() => {
                                  setMenu("Clogs");
                                }}
                              >
                                <Link
                                  to="/foot-protection/clogs"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Clogs")}
                                </Link>
                                {menu === "Clogs" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Low shoes");
                                }}
                              >
                                <Link
                                  to="/foot-protection/low-shoes"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Low_shoes")}
                                </Link>
                                {menu === "Low shoes" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Sandals");
                                }}
                              >
                                <Link
                                  to="/foot-protection/sandals"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Sandals")}
                                </Link>
                                {menu === "Sandals" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Slippers");
                                }}
                              >
                                <Link
                                  to="/foot-protection/slippers"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Slippers")}
                                </Link>
                                {menu === "Slippers" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Boots");
                                }}
                              >
                                <Link
                                  to="/foot-protection/boots"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Boots")}
                                </Link>
                                {menu === "Boots" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Winter boots");
                                }}
                              >
                                <Link
                                  to="/foot-protection/winter-boots"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Winter_boots")}
                                </Link>
                                {menu === "Winter boots" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Accessories");
                                }}
                              >
                                <Link
                                  to="/foot-protection/accessories"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Accessories")}
                                </Link>
                                {menu === "Accessories" ? <hr /> : null}
                              </li>
                            </ul>
                          </>
                        )}
                        {visibleButton === 5 && (
                          <>
                            <p className="uppercase tracking-wider text-red-500 font-bold text-[13px]">
                              {t("Respiratory_protection")}
                            </p>
                            <ul className="mt-4 text-[15px]">
                              <li
                                onClick={() => {
                                  setMenu("Respirator masks");
                                }}
                              >
                                <Link
                                  to="/respiratory-protection/respirator-masks"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Respirator masks")}
                                </Link>
                                {menu === "Respirator masks" ? <hr /> : null}
                              </li>
                            </ul>
                          </>
                        )}
                        {visibleButton === 6 && (
                          <>
                            <p className="uppercase tracking-wider text-red-500 font-bold text-[13px]">
                              Eye protection
                            </p>
                            <ul className="mt-4 text-[15px]">
                              <li
                                onClick={() => {
                                  setMenu("Ultraviolet filter");
                                }}
                              >
                                <Link
                                  to="/eye-protection/ultraviolet-filter"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Ultraviolet_filter")}
                                </Link>
                                {menu === "Ultraviolet filter" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Sunglare filter");
                                }}
                              >
                                <Link
                                  to="/eye-protection/sunglare-filter"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Sunglare_filter")}
                                </Link>
                                {menu === "Sunglare filter" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Accessories");
                                }}
                              >
                                <Link
                                  to="/eye-protection/accessories"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Accessories")}
                                </Link>
                                {menu === "Accessories" ? <hr /> : null}
                              </li>
                            </ul>
                          </>
                        )}

                        {visibleButton === 7 && (
                          <>
                            <p className="uppercase tracking-wider text-red-500 font-bold text-[13px]">
                              {t("Hand_arm_protection")}
                            </p>
                            <ul className="mt-4 text-[15px]">
                              <li
                                onClick={() => {
                                  setMenu("Accessories");
                                }}
                              >
                                <Link
                                  to="/head-protection/accessories"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Accessories")}
                                </Link>
                                {menu === "Accessories" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Safety helmets");
                                }}
                              >
                                <Link
                                  to="/head-protection/safety-helmets"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Safety_helmets")}
                                </Link>
                                {menu === "Safety helmets" ? <hr /> : null}
                              </li>
                            </ul>
                          </>
                        )}

                        {visibleButton === 8 && (
                          <>
                            <p className="uppercase tracking-wider text-red-500 font-bold text-[13px]">
                              {t("Kids_selection")}
                            </p>
                            <ul className="mt-4 text-[15px]">
                              <li
                                onClick={() => {
                                  setMenu("Garments");
                                }}
                              >
                                <Link
                                  to="/kids-selection/garments"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Garments")}
                                </Link>
                                {menu === "Garments" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Hand protection");
                                }}
                              >
                                <Link
                                  to="/kids-selection/hand-protection"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Hand_protection")}
                                </Link>
                                {menu === "Hand protection" ? <hr /> : null}
                              </li>
                              <li
                                onClick={() => {
                                  setMenu("Foot protection");
                                }}
                              >
                                <Link
                                  to="/kids-selection/accessories"
                                  className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700"
                                >
                                  <i className="pi pi-chevron-circle-right text-xs mr-1"></i>
                                  {t("Accessories")}
                                </Link>
                                {menu === "Accessories" ? <hr /> : null}
                              </li>
                            </ul>
                          </>
                        )}
                      </div>
                      <div className="hidden xl:block">
                        <img
                          src={img01}
                          alt="Kompakt Arbeitsschutz "
                          title="Kompakt Arbeitsschutz "
                          className="w-full h-full object-cover rounded-xl"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="relative group px-3 py-2">
              <button
                className="hover:opacity-50 cursor-default"
                aria-haspopup="true"
              >
                {t("Inspiration")}
              </button>
              <div className="absolute left-1/2 -translate-x-1/2 top-3 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out z-50 min-w-[800px] transform">
                <div className="relative top-6 p-6 bg-white rounded-xl shadow-xl w-full">
                  <div className="w-100 h-10 bg-white transform rotate-45 absolute top-0 z-0 -translate-x-4 transition-transform group-hover:translate-x-3 duration-500 ease-in-out rounded-sm" />
                  <div className="relative z-10 grid grid-cols-2 gap-7 items-center">
                    <ul className="mt-3 text-[15px]">
                      <li>
                        <Link
                          to="#"
                          className="bg-transparent p-2 -mx-2  rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700 py-1 block"
                        >
                          {t("Catalogue")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="bg-transparent p-2 -mx-2  rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700 py-1 block"
                        >
                          {t("Category_brochures")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="bg-transparent p-2 -mx-2  rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700 py-1 block"
                        >
                          {t("Guidelines")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="bg-transparent p-2 -mx-2  rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700 py-2"
                        >
                          {t("Resistance_lists")}
                        </Link>
                      </li>
                    </ul>
                    <div>
                      <img
                        src={img01}
                        alt="Kompakt Arbeitsschutz "
                        title="Kompakt Arbeitsschutz "
                        className="w-full h-full object-cover rounded-xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="relative group px-3 py-2">
              <button
                className="hover:opacity-50 cursor-default"
                aria-haspopup="true"
              >
                {t("Company")}
              </button>
              <div className="absolute left-1/2 -translate-x-1/2 top-3 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out z-50 min-w-[800px] transform">
                <div className="relative top-6 p-6 bg-white rounded-xl shadow-xl w-full">
                  <div className="w-100 h-10 bg-white transform rotate-45 absolute top-0 z-0 -translate-x-4 transition-transform group-hover:translate-x-3 duration-500 ease-in-out rounded-sm" />
                  <div className="relative z-10 grid grid-cols-2 gap-7 items-center">
                    <ul className="mt-3 text-[15px]">
                      <li className="py-2">
                        <Link
                          to="#"
                          className="bg-transparent p-2 -mx-2  rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700 py-1 block"
                        >
                          {t("Field_service")}
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link
                          to="#"
                          className="bg-transparent p-2 -mx-2  rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700 py-1 block"
                        >
                          {t("International_partners")}
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link
                          to="#"
                          className="bg-transparent p-2 -mx-2  rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700 py-1 block"
                        >
                          {t("Quality_management")}
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link
                          to="#"
                          className="bg-transparent p-2 -mx-2  rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700 py-2"
                        >
                          {t("Sustainability")}
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link
                          to="#"
                          className="bg-transparent p-2 -mx-2  rounded-lg hover:bg-gradient-to-br hover:from-red-100 hover:to-red-50 hover:via-red-100  transition ease-in-out duration-300 text-red-500 font-semibold hover:text-red-700 py-2"
                        >
                          {t("Logistics")}
                        </Link>
                      </li>
                    </ul>
                    <div>
                      <img
                        src={img01}
                        alt="Kompakt Arbeitsschutz "
                        title="Kompakt Arbeitsschutz "
                        className="w-full h-full object-cover rounded-xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
        <nav>
          <ul>
            <li className="flex ">
              {localStorage.getItem("auth-token") ? (
                <button
                  type="button"
                  className="logout"
                  onClick={() => {
                    localStorage.removeItem("auth-token");
                    window.location.replace("/");
                  }}
                >
                  {t("Logout")}
                </button>
              ) : (
                <Link
                  to="/login"
                  className="rounded-lg mx-1 bg-red-500 px-3 py-2 font-semibold  text-white flex justify-center items-center hover:bg-red-400 ease-in-out transition "
                >
                  {t("Login")}
                </Link>
              )}

              <Link
                to="/cart"
                className="rounded-lg mx-1  px-6 py-2 font-semibold text-red text-2xl flex justify-center items-center hover:text-red-400 ease-in-out transition"
              >
                <i className="pi pi-shopping-cart"></i>
              </Link>

              <Link
                to="/searchbar"
                className="rounded-lg mx-1  px-6 py-2 font-semibold text-red text-2xl flex justify-center items-center hover:text-red-400 ease-in-out transition"
              >
                <i className="pi pi-search"></i>
                <div className="nav-cart-count absolute w-[18px] h-[18px] flex justify-center align-middle mt-[-40px] ml-[-130px] rounded-xl text-xs bg-red-500 text-white">
                  {getTotalCartItems()}
                </div>
              </Link>
              <div className="rounded-lg mx-1 px-6 py-2 font-semibold text-red text-1xl flex justify-center items-center hover:text-red-400 ease-in-out transition">
                <MdOutlineTranslate className="mr-2" />
                <select
                  id="language-selector"
                  onChange={(e) => changeLanguage(e.target.value)}
                  className="p-2 rounded-lg border border-red-500 hover:bg-red-500 hover:text-white cursor-pointer transition ease-linear text-black"
                  style={{
                    backgroundSize: "20px 20px",
                    backgroundRepeat: "no-repeat",
                    paddingLeft: "30px", // Offset to make room for the background image
                    backgroundPosition: "5px center",
                    backgroundImage: `url(${germanflag})`, // Corrected with backticks for string interpolation
                  }}
                >
                  <option value="en">English</option>
                  <option value="de">Deutsch</option>
                  <option value="sq">Shqip</option>
                </select>
              </div>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Navdesktop;
