import React from "react";
// import Product from "../components/product/Product";
// import Slider from "../components/home/Slider";
import OurCategories from "../components/home/OurCategories";
import Hero from "../components/hero/Hero";
import Popular from "../components/popular/Popular";
import Offerts from "../components/Offerts/Offerts";
import Newcollection from "../components/newcollection/Newcollection";
import Newletter from "../components/Newletter/Newletter";
import Sliderfrontpage from "../components/Slider/Sliderfrontpage";
// import Testtesti from "../components/test/Testtesti";

// import { Showproduct } from "../components/product/Showproduct";

export default function HomePage() {
  return (
    <div>
      {/* <Navdesktop /> */}
      {/* <Slider /> */}
      <Sliderfrontpage />

      {/* <Testtesti /> */}
      {/* <Product /> */}
      {/* <Showproduct /> */}
      <Hero />
      <Popular />
      <Offerts />
      <OurCategories />
      <Newcollection />
      <Newletter />
    </div>
  );
}
