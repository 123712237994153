import React from "react";
import { useTranslation } from "react-i18next";
const Aboutus = () => {
  const { t } = useTranslation();
  return (
    <div className="container-sm mx-auto px-4 ">
      <h2 className="text-red-500 font-bold text-2xl"> {t("Aboutus")}</h2>
      <hr className="border border-red-500 my-3 w-[100px]" />
      <p className="my-5 ">
        {t("aboutus_02")}{" "}
        <strong className="text-red-500">Kompakt Arbeitsschutz</strong> –{" "}
        {t("aboutus_01")}
      </p>
      <p className="my-5">{t("aboutus_03")}</p>
      <p className="my-5">{t("aboutus_04")}</p>
      <p className="my-5">
        {t("aboutus_05")}{" "}
        <a
          href="mailto:info@kompakt-arbeitsschutz.de"
          className="underline text-red-500"
        >
          info@kompakt-arbeitsschutz.de
        </a>{" "}
        {t("aboutus_06")}{" "}
        <a href="tel:+4924219590176" className="underline text-red-500">
          +49 2421 9590176
        </a>
        . {t("aboutus_07")}
      </p>
    </div>
  );
};

export default Aboutus;
