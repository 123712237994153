import React, { useState, useEffect, useCallback } from "react";

// import { Link } from "react-router-dom";

// img

// import { FaChevronRight } from "react-icons/fa";
// import { FaChevronLeft } from "react-icons/fa";

import { useTranslation } from "react-i18next";

import bgkompaktone from "../../assest/images/slider/kompakt_01_photo-min.webp";
import bgkompakttwo from "../../assest/images/slider/kompakt_02_photo-min.webp";
import bgkompaktthree from "../../assest/images/slider/kompakt_03_photo-min.webp";
import bgkompaktfour from "../../assest/images/slider/kompakt_04_photo-min.webp";

const Sliderfrontpage = () => {
  const { t } = useTranslation();
  const slideData = [
    {
      src: bgkompaktone,
      title: t("title_Hero"),
      copy: t("subtitle_Hero"),
    },
    {
      src: bgkompakttwo,
      title: t("title_Hero_01"),
      copy: t("subtitle_Hero_01"),
    },
    {
      src: bgkompaktthree,
      title: t("title_Hero_02"),
      copy: t("subtitle_Hero_02"),
    },
    {
      src: bgkompaktfour,
      title: t("title_Hero_03"),
      copy: t("subtitle_Hero_03"),
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slideData.length);
  }, [slideData.length]);

  useEffect(() => {
    const autoplay = setInterval(nextSlide, 5000);
    return () => clearInterval(autoplay); // Clean up interval on component unmount
  }, [nextSlide]); // Add nextSlide as a dependency here

  return (
    <div className="container-sm relative w-full h-screen bg-gray-800 flex">
      <div className="left-col relative w-full h-full overflow-hidden bg-cover bg-center bg-no-repeat">
        {slideData.map((slide, index) => (
          <div
            key={index}
            className={`slide absolute w-full h-full bg-cover bg-no-repeat bg-center transform transition-all duration-1000 ease-in-out ${
              index === currentIndex
                ? "opacity-100 translate-y-0 scale-125 z-10"
                : index === (currentIndex + 1) % slideData.length
                ? "opacity-0 translate-y-100 z-0"
                : "opacity-0 translate-y-[-100%] z-0"
            }`}
            style={{ backgroundImage: `url(${slide.src})` }}
          >
            <div
              className={`caption absolute top-1/2 left-1/4 transform -translate-y-1/2 transition-all duration-500 ease-in-out ${
                index === currentIndex
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-16"
              }`}
            >
              <div className="caption-heading text-white font-light">
                <h1 className="text-4xl mb-6 uppercase tracking-wider">
                  {slide.title}
                </h1>
              </div>
              <div className="caption-subhead text-white text-sm uppercase tracking-widest mb-10">
                <span>{slide.copy}</span>
              </div>
              <a
                href="#asd"
                className="btn inline-block px-4 py-2 text-xs uppercase tracking-wide rounded-sm font-semibold bg-red-500 text-white hover:bg-red-300 hover:text-white transition-all duration-250"
              >
                {t("show_more")}
              </a>
            </div>
          </div>
        ))}
      </div>

      {/* <div className="nav absolute left-0 top-0 w-full h-full  z-5">
        <Link
          to=""
          className="slide-up z-5 text-red-500 absolute top-1/2 left-0 transform -translate-x-full p-4 opacity-0 transition-opacity duration-250 ease-in-out"
        >
          <FaChevronLeft className="text-red-500" />
        </Link>
        <Link
          to=""
          className="slide-down absolute top-1/2 right-0 transform translate-x-full p-4 opacity-0 transition-opacity duration-250 ease-in-out"
          onClick={(e) => {
            e.preventDefault();
            nextSlide();
          }}
        >
          <FaChevronRight className="text-red-500" />
        </Link>
      </div> */}
    </div>
  );
};

export default Sliderfrontpage;
