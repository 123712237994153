import React from "react";

function InspirationPage() {
  return (
    <div className="p-6  text-gray-800">
      <h2 className="text-2xl font-bold mb-4">Imprint</h2>
      <div className="space-y-2">
        <p className="font-semibold">Kompakt GmbH</p>
        <p>Josef-Schregel-Straße 68</p>
        <p>52349 Düren</p>
      </div>

      <div className="mt-4 space-y-1">
        <p>
          <span className="font-semibold">Tel.:</span> 02421 262 485 5
        </p>
        <p>
          <span className="font-semibold">Email:</span> info@kompakt-umzug.de
        </p>
      </div>

      <div className="mt-4 space-y-1">
        <p>
          <span className="font-semibold">Register court:</span> District Court
          of Düren
        </p>
        <p>
          <span className="font-semibold">Registration number:</span> HRB 869
        </p>
        <p>
          <span className="font-semibold">Authorized Managing Director:</span>{" "}
          Valon Troni
        </p>
        <p>
          <span className="font-semibold">VAT identification number:</span>{" "}
          DE343110887
        </p>
      </div>
    </div>
  );
}

export default InspirationPage;
