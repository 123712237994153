import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import hand from "../../assest/home/hand_arm_protection-min.webp";
import Foot from "../../assest/home/foot_protection-min.webp";
import Respiratory from "../../assest/home/respiratory_protection-min.webp";
import Eye from "../../assest/home/Eyeprotection-min.webp";
import Head from "../../assest/home/hand_arm_protection-min.webp";
import Workwear from "../../assest/home/Workwear-min.webp";
import Protective from "../../assest/home/Protectiveclothing-min.webp";

const OurCategories = () => {
  const [categories] = React.useState([
    {
      id: 1,
      name: "Hand / arm protection",
      image: hand,
    },
    {
      id: 2,
      name: "Foot protection",
      image: Foot,
    },
    {
      id: 3,
      name: "Respiratory protection",
      image: Respiratory,
    },
    {
      id: 4,
      name: "Eye protection",
      image: Eye,
    },
    {
      id: 5,
      name: "Head protection",
      image: Head,
    },
    {
      id: 6,
      name: "Workwear",
      image: Workwear,
    },
    {
      id: 7,
      name: "Protective clothing",
      image: Protective,
    },
  ]);

  const [activeCategory, setActiveCategory] = React.useState(0); // Start at the first category
  const categoryWidth = 300; // Set category width in px
  const categoryHeight = 400; // Set category height in px

  // Handle moving left (prevent going past the first category)
  const handleMoveLeft = () => {
    if (activeCategory > 0) {
      setActiveCategory((prev) => prev - 1);
    }
  };

  // Handle moving right (prevent going past the last category)
  const handleMoveRight = () => {
    if (activeCategory < categories.length - 1) {
      setActiveCategory((prev) => prev + 1);
    }
  };

  return (
    <div className="relative w-full overflow-hidden">
      {/* Scrollable container */}
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{
          transform: `translateX(${-activeCategory * categoryWidth}px)`,
        }}
      >
        {categories.map((category, index) => (
          <div
            key={index}
            className="flex-shrink-0"
            style={{
              width: `${categoryWidth}px`,
              height: `${categoryHeight}px`,
              backgroundImage: `url(${category.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <h3 className="text-lg font-bold bg-black/20 text-white p-2">
              {category.name}
            </h3>
          </div>
        ))}
      </div>

      {/* Left and Right buttons for scrolling */}
      <button
        className={`absolute top-1/2 left-0 transform -translate-y-1/2 p-2 bg-red-400 transition ease-linear hover:bg-red-500 text-white rounded-2xl mx-3 ${
          activeCategory === 0 ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={handleMoveLeft}
        disabled={activeCategory === 0} // Disable button if on the first category
      >
        <FaChevronLeft />
      </button>
      <button
        className={`absolute top-1/2 right-0 transform -translate-y-1/2 p-2 bg-red-400 transition ease-linear hover:bg-red-500 text-white rounded-2xl mx-3 ${
          activeCategory === categories.length - 1
            ? "opacity-50 cursor-not-allowed"
            : ""
        }`}
        onClick={handleMoveRight}
        disabled={activeCategory === categories.length - 1} // Disable button if on the last category
      >
        <FaChevronRight />
      </button>
    </div>
  );
};

export default OurCategories;
