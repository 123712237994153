import React from "react";
import errorpage from "../assest/noFoundPage/404error.svg";

const NoPage = () => {
  return (
    <div>
      <div className="flex justify-center items-center">
        <img
          src={errorpage}
          className="w-1/2"
          alt="error 404 kompakt"
          title="error 404 kompakt"
        />
      </div>
    </div>
  );
};

export default NoPage;
