import React from "react";

import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const lastUpdatedDate = "November 9, 2024";

  return (
    <div className="container-sm mx-auto px-4">
      <h1 className="text-red-500 font-bold text-2xl">{t("PrivacyPolicy")}</h1>
      <hr className="border border-red-500 my-3 w-[100px]" />
      <p>
        <strong>{t("PrivacyPolicy23")}:</strong> {lastUpdatedDate}
      </p>

      <h2 className="font-bold my-4">1. {t("Introduction")}</h2>
      <p>
        {t("PrivacyPolicy_01")},{" "}
        <a
          href="https://www.kompakt-arbeitsschutz.de/"
          className="text-red-500 underline"
        >
          https://www.kompakt-arbeitsschutz.de/
        </a>
        .
      </p>

      <h2 className="font-bold my-4">2. {t("PrivacyPolicy_h2_2")} </h2>
      <p>{t("PrivacyPolicy_02")}</p>
      <ul className="mx-4">
        <li className="list-disc">{t("PrivacyPolicy_03")}</li>
        <li className="list-disc">{t("PrivacyPolicy_04")}</li>
        <li className="list-disc">{t("PrivacyPolicy_05")}</li>
      </ul>

      <h2 className="font-bold my-4">3. {t("PrivacyPolicy_h2_3")} </h2>
      <p>{t("PrivacyPolicy_06")}</p>
      <ul className="mx-4">
        <li className="list-disc">{t("PrivacyPolicy_07")}</li>
        <li className="list-disc">{t("PrivacyPolicy_08")}</li>
        <li className="list-disc">{t("PrivacyPolicy_09")}</li>
        <li className="list-disc">{t("PrivacyPolicy_10")}</li>
      </ul>

      <h2 className="font-bold my-4">4. {t("PrivacyPolicy_h2_4")} </h2>
      <p>{t("PrivacyPolicy_11")}</p>
      <ul className="mx-4">
        <li className="list-disc">{t("PrivacyPolicy_12")}</li>
        <li className="list-disc">{t("PrivacyPolicy_13")}</li>
        <li className="list-disc">{t("PrivacyPolicy_14")}</li>
      </ul>

      <h2 className="font-bold my-4">5. {t("PrivacyPolicy_h2_5")} </h2>
      <p>{t("PrivacyPolicy_15")}</p>

      <h2 className="font-bold my-4">6. {t("PrivacyPolicy_h2_6")} </h2>
      <p>{t("PrivacyPolicy_16")}</p>

      <h2 className="font-bold my-4">7. {t("PrivacyPolicy_h2_7")} </h2>
      <p>{t("PrivacyPolicy_17")}</p>
      <ul className="mx-4">
        <li className="list-disc">{t("PrivacyPolicy_18")}</li>
        <li className="list-disc">{t("PrivacyPolicy_19")}</li>
        <li className="list-disc">{t("PrivacyPolicy_20")}</li>
      </ul>
      <p className="my-4">
        {t("PrivacyPolicy24")}{" "}
        <a href="mailto:info@kompakt-arbeitsschutz.de">
          info@kompakt-arbeitsschutz.de
        </a>
        .
      </p>

      <h2 className="font-bold my-4">8. {t("PrivacyPolicy_h2_8")}</h2>
      <p className="my-4">{t("PrivacyPolicy_21")}</p>

      <h2 className="font-bold my-4">9. {t("PrivacyPolicy_h2_9")} </h2>
      <p className="my-4">{t("PrivacyPolicy_22")}</p>
      <address className="text-red-500">
        <strong>Kompakt Arbeitsschutz</strong>
        <br />
        Email:{" "}
        <a href="mailto:info@kompakt-arbeitsschutz.de">
          info@kompakt-arbeitsschutz.de
        </a>
        <br />
        {t("Phone")}: +49 2421 9590176
        <br />
        Address: Josef-Schregel-Str. 68, 52349 Düren, DE
      </address>
    </div>
  );
};

export default PrivacyPolicy;
