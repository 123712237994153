import React from "react";
import { Link } from "react-router-dom";

const Item = (props) => {
  console.log("Image URL:", props.image);

  return (
    <div className="w-[350px] max-xl:w-[220px] max-xs:w-[160px] hover:scale-105 bg-white transition-transform duration-300 ease-linear p-4 shadow-lg rounded-lg border border-red-500">
      {/* Image Styling */}
      <Link to={`/product/${props.id}`} onClick={() => window.scrollTo(0, 0)}>
        <img
          src={props.image[0]}
          onError={(e) => (e.target.src = "path/to/placeholder/image.jpg")}
          alt={props.name}
          className="w-[280px] h-[200px] object-cover rounded-md mx-auto max-xl:w-[220px] max-xs:w-[160px]"
        />
      </Link>

      {/* Name Styling */}
      <p className="mt-4 text-xl font-semibold text-gray-800 max-lg:text-lg max-sm:text-base">
        {props.name}
      </p>

      {/* Description Styling */}
      {props.description && (
        <p className="text-gray-600 text-sm mt-2 max-lg:text-xs max-sm:text-[10px]">
          {props.description}
        </p>
      )}

      {/* Price Section */}
      {/* <div className="flex items-center gap-4 mt-4">
        <div className="text-red-500 text-2xl font-bold max-lg:text-xl max-sm:text-lg">
          ${props.new_price}
        </div>
        {props.old_price && (
          <div className="text-gray-500 text-lg line-through max-lg:text-base max-sm:text-sm">
            ${props.old_price}
          </div>
        )}
      </div> */}
    </div>
  );
};

export default Item;
