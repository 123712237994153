import React from "react";

const Guidelines = () => {
  return (
    <div>
      <p>Guidelines</p>
    </div>
  );
};

export default Guidelines;
