import React from "react";

function ServicesPage() {
  return (
    <div>
      <h1>Services</h1>
    </div>
  );
}

export default ServicesPage;
