import React, { createContext, useEffect, useState } from "react";

export const Shopcontext = createContext(null);

const getDefaultCart = () => {
  let cart = {};
  for (let index = 0; index < 300 + 1; index++) {
    cart[index] = 0;
  }
  return cart;
};

const ShopcontextProvider = (props) => {
  const [allProducts, setAllProducts] = useState([]);
  const [cartItems, setCartItems] = useState(getDefaultCart());
  const [error, setError] = useState(null); // Error state
  const [prices, setPrices] = useState({}); // Store prices here

  useEffect(() => {
    fetch("http://localhost:5000/api/products/allproducts")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok"); // Handle errors
        }
        return response.json();
      })
      .then((data) => setAllProducts(Array.isArray(data) ? data : []))
      .catch((error) => {
        console.error("Error fetching products:", error);
        setError(error.message); // Set error message
      });

    // Fetch cart items from backend if authenticated
    if (localStorage.getItem("auth-token")) {
      fetch("http://localhost:5000/api/getcart", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "auth-token": `${localStorage.getItem("auth-token")}`,
          "Content-Type": "application/json",
        },
        body: "",
      })
        .then((response) => response.json())
        .then((data) => setCartItems(data));
    }
  }, []); // Empty dependency array ensures the effect runs only once

  // Function to add item to cart
  const addToCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
    if (localStorage.getItem("auth-token")) {
      fetch("http://localhost:5000/api/addtocart", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "auth-token": `${localStorage.getItem("auth-token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ itemId: itemId }),
      }).then((response) => response.json());
    }
  };

  // Function to remove item from cart
  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
    if (localStorage.getItem("auth-token")) {
      fetch("http://localhost:5000/api/removefromcart", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "auth-token": `${localStorage.getItem("auth-token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ itemId: itemId }),
      }).then((response) => response.json());
    }
  };

  // Function to set prices (called by admin)
  const setPricesForCart = (updatedPrices) => {
    setPrices(updatedPrices);
  };

  // Calculate total amount in the cart based on updated prices
  const getTotalCartAmount = () => {
    return Object.keys(cartItems).reduce((total, item) => {
      if (cartItems[item] > 0) {
        const itemInfo = allProducts.find(
          (product) => product.id === Number(item)
        );
        // If the price is set in the prices state, use that. Otherwise, fall back to the product's default price
        const price = prices[item] || (itemInfo ? itemInfo.new_price : 0);
        return total + price * cartItems[item];
      }
      return total;
    }, 0);
  };

  // Get total number of items in the cart
  const getTotalCartItems = () => {
    return Object.values(cartItems).reduce((total, count) => total + count, 0);
  };

  const contextValue = {
    allProducts,
    cartItems,
    addToCart,
    removeFromCart,
    getTotalCartAmount,
    getTotalCartItems,
    setPricesForCart, // Allow the admin to update prices
    prices, // Pass prices to the components
    error, // Include error state in context
  };

  return (
    <Shopcontext.Provider value={contextValue}>
      {props.children}
      {error && <div className="error">{error}</div>}{" "}
      {/* Display error if present */}
    </Shopcontext.Provider>
  );
};

export default ShopcontextProvider;
