import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./i18n";
import reportWebVitals from "./reportWebVitals";

import ShopcontextProvider from "./context/Shopcontext";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <ShopcontextProvider>
    <App />
  </ShopcontextProvider>
);

// Log results to the console or send to an analytics endpoint
reportWebVitals((metric) => {
  console.log(metric);
  // Optionally, send metrics to a server for analysis
  // fetch('https://your-analytics-endpoint.com', {
  //   method: 'POST',
  //   body: JSON.stringify(metric),
  //   headers: { 'Content-Type': 'application/json' },
  // });
});
