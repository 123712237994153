import React from "react";

import heroimg from "../../assest/images/hero/image01.png";
import kompaktnew from "../../assest/images/hero/kompakt_red_new_colleciton.png";
import { FaArrowRight } from "react-icons/fa6";

const Hero = () => {
  return (
    <div className="flex justify-between xl:justify-between items-center h-auto flex-wrap border border-b-red-500 border-t-red-500 border-x-0 ">
      <div className="hero_left ml-[50px]">
        <h2 className="text-black text-3xl xl:text-5xl font-bold my-5">
          New Arrivals Only
        </h2>
        <div>
          <div className="hand-hand-icon flex items-center gap-3 my-5">
            <p className="text-black text-3xl xl:text-5xl font-bold">new</p>
            <img src={kompaktnew} alt="kompakt new" className="w-[300px]" />
          </div>
          <p className="text-black text-3xl xl:text-5xl font-bold my-3">
            collection
          </p>
          <p className="text-black text-3xl xl:text-5xl font-bold my-3">
            for everyone
          </p>
        </div>
        {/* hero last colleciton */}
        <div className="hero-last-btn my-5 flex justify-center items-center gap-3 w-[310px] h-[50px] rounded-xl t-[30px] bg-red-500 text-2xl font-semibold text-white">
          <div>Lastet Collection</div>
          <FaArrowRight />
        </div>
      </div>
      <div className="hero-right mr-[50px]">
        <img src={heroimg} alt="kompakt hero" className="w-[500px] pt-10" />
      </div>
    </div>
  );
};

export default Hero;
