import React from "react";
import { useContext } from "react";
import { Shopcontext } from "../context/Shopcontext";
import { FaSortAlphaDown } from "react-icons/fa";
import Item from "../components/Item/Item";

const ShopCategoryPage = (props) => {
  const { allProducts } = useContext(Shopcontext);

  return (
    <div>
      {props.banner && <props.banner />}
      <div className="shopcategory-indexSort block mx-[30px] my-auto xl:w-[82%]">
        <div className="shopcategory-indexSort flex mx-0 mt-[170px] justify-between  items-center">
          <p className="xl:ml-[170px]">
            <span>Showing 1-12</span> out of {allProducts.length} products
          </p>
          <div className="shopcategory-sort  px-[10px] py-[10px] flex justify-center items-center rounded-lg border border-red-500 hover:bg-red-500 hover:text-white cursor-pointer transition ease-linear">
            Sort by: <FaSortAlphaDown />{" "}
          </div>
        </div>

        <div className="shopcategory-products m-5 xl:mx-[170px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-[80px]">
          {allProducts.map((item, i) => {
            if (
              item.category
                .toLowerCase()
                .includes(props.category.toLowerCase()) ||
              item.subcategory
                .toLowerCase()
                .includes(props.category.toLowerCase())
            ) {
              return (
                <Item
                  key={i}
                  id={item.id}
                  name={item.name}
                  image={item.images}
                />
              );
            } else {
              return null;
            }
          })}
        </div>

        <div className="shopcategory-loadmore flex justify-center my-10">
          <button
            type="button"
            className="border border-red-500 w-[150px] h-[60px] font-semibold text-black rounded-lg hover:bg-red-500 hover:text-white transition ease-linear"
          >
            Explore more
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShopCategoryPage;
