import React from "react";
import { useTranslation } from "react-i18next";

const Datenschutz = () => {
  const { t } = useTranslation();
  return (
    <div className="container-sm mx-auto px-4">
      <h2 className="text-red-500 font-bold text-2xl">{t("datenschutz_01")}</h2>
      <hr className="border border-red-500 my-3 w-[100px]" />
      <p className="my-4">{t("datenschutz_02")}</p>

      <h3 className="font-bold my-4">1. {t("datenschutz_03")}</h3>
      <p className="my-4">{t("datenschutz_04")}</p>
      <ul className="mx-4">
        <li className="list-disc">
          <strong>{t("datenschutz_05")}:</strong> {t("datenschutz_06")}
        </li>
        <li className="list-disc">
          <strong>{t("datenschutz_07")}:</strong> {t("datenschutz_08")}
        </li>
        <li className="list-disc">
          <strong> {t("datenschutz_09")}:</strong> {t("datenschutz_10")}
        </li>
      </ul>

      <h3 className="my-4 font-bold">2. {t("datenschutz_11")}</h3>
      <p>{t("datenschutz_12")}</p>
      <ul className="mx-4">
        <li className="list-disc">{t("datenschutz_13")}</li>
        <li className="list-disc">{t("datenschutz_14")}</li>
        <li className="list-disc">{t("datenschutz_15")}</li>
      </ul>

      <h3 className="my-4 font-bold">3. {t("datenschutz_16")}</h3>
      <p className="my-4">{t("datenschutz_17")}</p>

      <h3 className="font-bold">4. {t("datenschutz_18")}</h3>
      <p className="my-4">{t("datenschutz_19")}</p>

      <h3 className="font-bold my-4">5. {t("datenschutz_20")}</h3>
      <p className="my-4">
        {t("datenschutz_21")}
        <a href="mailto:info@kompakt-arbeitsschutz.de">
          {" "}
          info@kompakt-arbeitsschutz.de
        </a>
        .
      </p>

      <h3 className="my-4 font-bold">6. {t("datenschutz_22")}</h3>
      <p className="my-4">{t("datenschutz_23")}</p>

      <h3 className="my-4 font-bold">{t("datenschutz_24")}</h3>
      <p className="my-4">{t("datenschutz_25")}</p>
      <address className="text-red-500 my-4">
        <strong>Kompakt Arbeitsschutz</strong>
        <br />
        Josef-Schregel-Str. 68,
        <br />
        52349 Düren, Germany
        <br />
        <strong className="text-black">Email:</strong>{" "}
        <a href="mailto:info@kompakt-arbeitsschutz.de">
          info@kompakt-arbeitsschutz.de
        </a>
        <br />
        <strong className="text-black">{t("Phone")}:</strong> +49 2421 9590176
      </address>
    </div>
  );
};

export default Datenschutz;
