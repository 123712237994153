import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// logo import
import logo from "../../assest/slider/kompakt_logo_red.svg";

const Footerdesktop = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer className="bg-[#fff] relative border border- border-t-red-500">
        <div className="container mx-auto">
          <div className="border-b border-red-500 pb-2.5 pt-5">
            <div className="flex flex-wrap">
              <div className="w-full xl:w-1/3 md:w-1/3 mb-7.5 px-4">
                <div className="flex items-start">
                  <i className="pi pi-map-marker text-red-500 text-3xl mt-2.5"></i>
                  <div className="ml-4">
                    <h4 className="text-black  text-lg font-semibold mb-0.5">
                      {t("Find_us")}
                    </h4>
                    <a
                      href="https://maps.app.goo.gl/zhf5XcaFKuKggDag8"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Josef-Schregel-Straße 68, 52349 Düren, Germany"
                    >
                      <span className="text-gray-500 hover:text-red-500 text-sm">
                        Josef-Schregel-Straße 68, 52349 Düren, Germany
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full xl:w-1/3 md:w-1/3 mb-7.5 px-4">
                <div className="flex items-start">
                  <i className="pi pi-phone text-red-500 text-3xl mt-2.5"></i>
                  <div className="ml-4">
                    <h4 className="text-black text-lg font-semibold mb-0.5">
                      {t("Call_us")}
                    </h4>
                    <a
                      href="tel:+4924219590177"
                      aria-label="Kompakt Arbeitsschutz  -  +49 2421 9590177"
                    >
                      <span className="text-gray-500 hover:text-red-500 transition ease-linear text-sm">
                        +49 2421 9590177
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full xl:container md:w-1/3 mb-7.5 px-4">
                <div className="flex items-start ">
                  <i className="pi pi-envelope text-red-500 text-3xl mt-2.5"></i>
                  <div className="ml-4">
                    <h4 className="text-black text-lg font-semibold mb-0.5">
                      {t("Mail_us")}
                    </h4>
                    <a
                      href="mailto:info@kompakt-arbeitsschutz.de"
                      title="Kompakt Arbeitsschutz mail - info@kompakt-arbeitsschutz.de"
                      rel="noreferrer"
                      aria-label="Kompakt Arbeitsschutz mail - info@kompakt-arbeitsschutz.de"
                    >
                      <span className="text-gray-500 hover:text-red-500 text-sm">
                        info@kompakt-arbeitsschutz.de
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative z-20 pt-5 pb-1">
            <div className="flex flex-wrap ">
              <div className="w-full xl:w-1/3 lg:w-1/3 mb-12.5 px-4">
                <div>
                  <div className="mb-4">
                    <a href="index.html">
                      <img src={logo} className="max-w-[350px]" alt="logo" />
                    </a>
                  </div>
                  <div className="text-[#7e7e7e] text-sm mb-3.5 leading-6">
                    <p>
                      <b>Kompakt Arbeitsschutz</b> {t("footer_text")}
                    </p>
                  </div>
                  <div>
                    <span className="text-black transition ease-linear hover:text-red-500 text-xl font-semibold block mb-5">
                      {t("Follow_us")}
                    </span>
                    <ul className="flex">
                      <li className="mr-2.5">
                        <Link href="#" className="">
                          <i className="pi pi-facebook text-3xl text-black hover:text-red-500 transition ease-linear "></i>
                        </Link>
                      </li>
                      <li className="mr-2.5">
                        <Link href="#" className="">
                          <i className="pi pi-twitter text-3xl text-black hover:text-red-500 transition ease-linear"></i>
                        </Link>
                      </li>
                      <li className="mr-2.5">
                        <Link href="#" className="">
                          <i className="pi pi-instagram text-3xl text-black hover:text-red-500 transition ease-linear"></i>
                        </Link>
                      </li>
                      <li>
                        <Link href="#" className="">
                          <i className="pi pi-youtube text-3xl text-black hover:text-red-500 transition ease-linear"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 mb-7.5 px-4">
                <div>
                  <h3 className="text-black text-lg font-semibold mb-10 relative before:absolute before:left-0 before:bottom-[-15px] before:h-0.5 before:w-12 before:bg-red-500">
                    {t("Workwear")}
                  </h3>
                  <ul>
                    <li className="mb-3.5">
                      <Link
                        to="/protective-clothing"
                        className=" text-gray-500  transition ease-linear   hover:text-red-500  text-sm"
                      >
                        {t("Protective_clothing")}
                      </Link>
                    </li>
                    <li className="mb-3.5">
                      <Link
                        to="/hand-arm-protection"
                        className="text-gray-500 hover:text-red-500  text-sm"
                      >
                        {t("Hand_arm_protection")}
                      </Link>
                    </li>
                    <li className="mb-3.5">
                      <Link
                        to="/foot-protection"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500  text-sm"
                      >
                        {t("Foot_protection")}
                      </Link>
                    </li>
                    <li className="mb-3.5">
                      <Link
                        to="/respiratory-protection"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500  text-sm"
                      >
                        {t("Respiratory_protection")}
                      </Link>
                    </li>
                    <li className="mb-3.5">
                      <Link
                        to="/eye-protection"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500  text-sm"
                      >
                        {t("Eye_protection")}
                      </Link>
                    </li>
                    <li className="mb-3.5">
                      <Link
                        to="/head-protection"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500  text-sm"
                      >
                        {t("Head_protection")}
                      </Link>
                    </li>
                    <li className="mb-3.5">
                      <Link
                        to="/kids-selection"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500  text-sm"
                      >
                        {t("Kids_selection")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 mb-7.5 px-4">
                <div>
                  <h3 className="text-black text-lg font-semibold mb-10 relative before:absolute before:left-0 before:bottom-[-15px] before:h-0.5 before:w-12 before:bg-red-500">
                    {t("Inspiration")}
                  </h3>
                  <ul>
                    <li className="mb-3.5">
                      <Link
                        to="/catalogue"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500 text-sm"
                      >
                        {t("Catalogue")}
                      </Link>
                    </li>
                    <li className="mb-3.5">
                      <Link
                        to="/category-brochures"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500 text-sm"
                      >
                        {t("Category_brochures")}
                      </Link>
                    </li>
                    <li className="mb-3.5">
                      <Link
                        to="/guidelines"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500 text-sm"
                      >
                        {t("Guidelines")}
                      </Link>
                    </li>
                    <li className="mb-3.5">
                      <Link
                        to="/resistance-lists"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500 text-sm"
                      >
                        {t("Resistance_lists")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 mb-7.5 px-4">
                <div>
                  <h3 className="text-black text-lg font-semibold mb-10 relative before:absolute before:left-0 before:bottom-[-15px] before:h-0.5 before:w-12 before:bg-red-500">
                    {t("Company")}
                  </h3>
                  <ul>
                    <li className="mb-3.5">
                      <Link
                        to="/field-service"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500 text-sm"
                      >
                        {t("Field_service")}
                      </Link>
                    </li>
                    <li className="mb-3.5">
                      <Link
                        to="/about-us"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500 text-sm"
                      >
                        {t("Aboutus")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 mb-7.5 px-4">
                <div>
                  <h3 className="text-black text-lg font-semibold mb-10 relative before:absolute before:left-0 before:bottom-[-15px] before:h-0.5 before:w-12 before:bg-red-500">
                    {t("Information")}
                  </h3>
                  <ul>
                    <li className="mb-3.5">
                      <Link
                        to="/inspiration"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500 text-sm"
                      >
                        {t("Impressum")}
                      </Link>
                    </li>
                    <li className="mb-3.5">
                      <Link
                        to="/datenschutz"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500  text-sm"
                      >
                        {t("Datenschutz")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white border border-t-red-500 border-x-0 border-b-0 py-6">
            <div className="container mx-auto">
              <div className="flex flex-wrap ">
                <div className="w-full xl:w-1/2 lg:w-1/2 text-center lg:text-left px-4">
                  <p className=" text-gray-500  transition ease-linear  text-sm m-0">
                    {t("Copyright")} &copy; {new Date().getFullYear()},{" "}
                    {t("allri")}{" "}
                    <Link href="#" className="text-[#ff5e14]">
                      Kompakt Arbeitsschutz
                    </Link>
                  </p>
                </div>
                <div className="w-full xl:w-1/2 lg:w-1/2 text-center lg:text-right px-4">
                  <ul className="flex items-center justify-center lg:justify-end">
                    <li className="mr-6">
                      <Link
                        to="/privacy-policy"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500 text-sm"
                      >
                        {t("PrivacyPolicy")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="#"
                        className=" text-gray-500  transition ease-linear  hover:text-red-500 text-sm"
                      >
                        {t("tc")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footerdesktop;
