import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        Workwear: "Workwear",
        Trousers: "Trousers",
        Jackets: "Jackets",
        Waistcoats: "Waistcoats",
        Pullovers: "Pullovers",
        Shirts: "Shirts",
        Aprons: "Aprons",
        Accessories: "Accessories",
        Protective_clothing: "Protective clothing",
        Protective_aprons: "Protective aprons",
        Protective_overalls: "Protective overalls",
        High_visibility_clothing: "High-visibility clothing",
        Hand_arm_protection: "Hand / arm protection",
        Arm_protection: "Arm protection",
        Antistatic_gloves: "Antistatic gloves",
        Chemical_protective_gloves: "Chemical protective gloves",
        Mechanic_s_gloves: "Mechanic's gloves",
        Cut_protection_gloves: "Cut protection gloves",
        Welding_gloves: "Welding gloves",
        Puncture_protection_gloves: "Puncture protection gloves",
        Winter_gloves: "Winter gloves",
        Leather_gloves: "Leather gloves",
        Nitrile_gloves: "Nitrile gloves",
        Knitted_gloves: "Knitted gloves",
        PVC_gloves: "PVC gloves",
        Cotton_gloves: "Cotton gloves",
        Disposable_gloves: "Disposable gloves",
        Foot_protection: "Foot protection",
        Clogs: "Clogs",
        Low_shoes: "Low shoes",
        Sandals: "Sandals",
        Slippers: "Slippers",
        Boots: "Boots",
        Winter_boots: "Winter boots",
        Respiratory_protection: "Respiratory protection",
        Respirator_masks: "Respirator masks",
        Eye_protection: "Eye protection",
        Ultraviolet_filter: "Ultraviolet filter",
        Sunglare_filter: "Sunglare filter",
        Head_protection: "Head protection",
        Safety_helmets: "Safety helmets",
        Kids_selection: "Kids selection",
        Garments: "Garments",
        Hand_protection: "Hand protection",
        Product: "Product",
        Service: "Service",
        "Protective aprons": "Protective aprons",
        Inspiration: "Inspiration",
        Catalogue: "Catalogue",
        Category_brochures: "Category brochures",
        Guidelines: "Guidelines",
        Resistance_lists: "Resistance lists",
        Company: "Company",
        Field_service: "Field service",
        International_partners: "International partners",
        Quality_management: "Quality management",
        Sustainability: "Sustainability",
        Logistics: "Logistics",
        Logout: "Logout",
        Login: "Login",
        Find_us: "Find us",
        Call_us: "Call us",
        Mail_us: "Mail us",
        footer_text:
          "offers high-quality workwear and protective equipment, including clothing, hand, foot, eye, and respiratory protection. They provide consulting, training, and sales support, focusing on safety andcompliance for various industries.",
        Follow_us: "Follow us",
        Aboutus: "About us",
        Information: "Information",
        Impressum: "Impressum",
        Datenschutz: "Data Protection",
        Copyright: "Copyright",
        allri: "All Right Reserved",
        PrivacyPolicy: "Privacy Policy",
        tc: "Terms & Conditions",
        inspiration_01: "Inspiration at Kompakt Arbeitsschutz",
        inspiration_02:
          "Since 2012, Kompakt Arbeitsschutz has been committed to providing safety, comfort, and reliability in every piece of protective equipment we offer. Our dedication to quality and innovation inspires us to ensure that every worker feels secure and empowered on the job.",
        inspiration_03: "Driven by Purpose",
        inspiration_04:
          "We believe that protective workwear should not only safeguard but also support productivity and comfort. Each item we design, from workwear to specialized protective clothing, is crafted with high standards, ensuring our clients stay protected in all work conditions.",
        inspiration_05: "Innovation for Every Need",
        inspiration_06:
          "At Kompakt Arbeitsschutz, we continuously seek out advancements in safety technology to bring you cutting-edge solutions for hand, arm, foot, respiratory, and eye protection. We understand that safety means covering every angle, and we’re inspired by our commitment to delivering comprehensive protection.",
        inspiration_07: "Empowering All Ages",
        inspiration_08:
          "From adults to our special kids’ selection, our products ensure that safety begins early. We want everyone—no matter their age or role—to have access to the best protection available.",
        inspiration_09:
          "Let us inspire you to work with confidence. At Kompakt Arbeitsschutz, we’re here to support you every step of the way.",
        Fieldservice_01: "Field Service",
        Fieldservice_02:
          "Our Field Service team provides on-site support to ensure the best fit, comfort, and functionality of our products for your workforce.",
        Fieldservice_03:
          "We offer consultations and fittings directly at your location, making it easier to equip your team with the right protective clothing and accessories.",
        Fieldservice_04:
          "From measurements to product selection and customization, our experts are here to support your specific safety needs.",
        Fieldservice_05: "All rights reserved.",
        Phone: "Phone",
        aboutus_01:
          "your trusted partner for premium workwear and protective solutions. Since our founding in 2012 in Düren, Germany, we've been dedicated to delivering high-quality safety apparel and protective gear tailored for diverse industries. Our products are carefully curated to ensure durability, comfort, and optimal protection, helping you stay safe on the job, every day.",
        aboutus_02: "Welcome to",
        aboutus_03:
          "At Kompakt Arbeitsschutz, we prioritize your safety. Our extensive range includes workwear, protective clothing, hand and arm protection, foot protection, respiratory and eye protection, and headgear. We even cater to young ones with our dedicated kids' collection, so the whole family can benefit from reliable, safe workwear.",
        aboutus_04:
          "Backed by years of experience and a commitment to excellence, our team is here to support you with expert guidance and outstanding customer service. Whether you're an individual worker or a large enterprise, we have the expertise and products to meet your specific needs.",
        aboutus_05: "Feel free to reach out to us at",
        aboutus_06: "or call us at",
        aboutus_07:
          "Thank you for choosing Kompakt Arbeitsschutz, where safety meets reliability.",
        datenschutz_01: "Data Protection Policy",
        datenschutz_02:
          "At Kompakt Arbeitsschutz, we prioritize the security and privacy of your personal information. We adhere to GDPR and other relevant data protection laws to ensure your data is handled responsibly and transparently.",
        datenschutz_03: "Data Collection",
        datenschutz_04:
          "We collect personal information solely for the purpose of processing orders, providing customer support, and improving your shopping experience. This may include:",
        datenschutz_05: "Contact Information",
        datenschutz_06: "such as your name, address, and phone number.",
        datenschutz_07: "Payment Information",
        datenschutz_08: "securely processed through trusted payment gateways.",
        datenschutz_09: "Preferences and Feedback",
        datenschutz_10: "for enhancing your experience and tailoring services.",
        datenschutz_11: "Use of Your Data",
        datenschutz_12: "Your information is used to:",
        datenschutz_13: "Fulfill and deliver orders",
        datenschutz_14: "Communicate order updates and relevant information",
        datenschutz_15:
          "Personalize your experience and recommend suitable products (if consent is given)",
        datenschutz_16: "Data Security",
        datenschutz_17:
          "Kompakt Arbeitsschutz uses advanced encryption and security measures to safeguard your information. Our systems are designed to prevent unauthorized access, and our payment processing partners are PCI-DSS compliant to ensure your payment data is secure.",
        datenschutz_18: "Third-Party Sharing",
        datenschutz_19:
          "We only share your data with essential third parties, such as payment processors and delivery partners, strictly to complete your transactions. We never sell or misuse your data.",
        datenschutz_20: "Your Rights",
        datenschutz_21:
          "You have full rights over your personal information, including the ability to access, update, or delete your data. To exercise these rights, please contact us at",
        datenschutz_22: "Cookies and Tracking",
        datenschutz_23:
          "To enhance website functionality and user experience, we use cookies. You can adjust your cookie preferences directly in your browser settings.",
        datenschutz_24: "Contact",
        datenschutz_25:
          "For questions or concerns regarding data protection, please contact us:",
        PrivacyPolicy_01:
          "Welcome to Kompakt Arbeitsschutz! We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you visit or make a purchase on our website.",
        PrivacyPolicy_02:
          "We collect information to provide you with the best possible service. This includes:",
        PrivacyPolicy_03:
          "Personal Information: Your name, email address, phone number, shipping address, and payment details when you make a purchase or create an account.",
        PrivacyPolicy_04:
          "Technical Information: Such as your IP address, browser type, device details, and website usage data, collected through cookies and similar technologies.",
        PrivacyPolicy_05:
          "Purchase History: Records of past orders to enhance customer support and recommend relevant products.",
        PrivacyPolicy_06: "We use your data for various purposes, including:",
        PrivacyPolicy_07:
          "Processing and delivering orders, including managing returns and exchanges.",
        PrivacyPolicy_08:
          "Communicating with you about orders, promotions, and customer inquiries.",
        PrivacyPolicy_09:
          "Enhancing our website and services for a better user experience.",
        PrivacyPolicy_10:
          "Preventing fraud, ensuring transaction security, and complying with legal requirements.",
        PrivacyPolicy_11:
          "We only share your information when necessary, including with:",
        PrivacyPolicy_12:
          "Service Providers: Such as payment processors and shipping partners, to fulfill orders and deliver services.",
        PrivacyPolicy_13:
          "Legal Compliance: To meet legal obligations or protect our rights, property, or safety.",
        PrivacyPolicy_14:
          "Business Transfers: In cases of merger, acquisition, or asset sale.",
        PrivacyPolicy_15:
          "We use cookies to improve your experience, remember preferences, and analyze website traffic. You may disable cookies through your browser settings, but some features may be limited as a result.",
        PrivacyPolicy_16:
          "We take measures to secure your information. Sensitive data, such as payment details, is encrypted. However, please note that no internet transmission method is completely secure.",
        PrivacyPolicy_17:
          "Depending on your location, you may have rights concerning your data, such as:",
        PrivacyPolicy_18:
          "Accessing, correcting, or deleting your personal information.",
        PrivacyPolicy_19:
          "Restricting or objecting to specific processing activities.",
        PrivacyPolicy_20:
          "Withdrawing consent where processing is based on consent.",
        PrivacyPolicy_21:
          "We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons. The updated policy will be posted here with the effective date.",
        PrivacyPolicy_22:
          "If you have any questions or concerns about this Privacy Policy, please contact us at:",
        PrivacyPolicy23: "Effective Date",
        Introduction: "Introduction",
        PrivacyPolicy_h2_2: "2. Information We Collect",
        PrivacyPolicy_h2_3: "3. How We Use Your Information",
        PrivacyPolicy_h2_4: "4. Sharing Your Information",
        PrivacyPolicy_h2_5: "5. Cookies and Tracking",
        PrivacyPolicy_h2_6: "6. Data Security",
        PrivacyPolicy_h2_7: "7. Your Rights",
        PrivacyPolicy_h2_8: "8. Changes to This Policy",
        PrivacyPolicy_h2_9: "9. Contact Us",
        PrivacyPolicy24: "For any data-related requests, please contact us at",
        SIGN_IN: "Sign In",
        email: "Your email address",
        memeber: "Already have an account?",
        Terms_01: "I agree with the",
        Terms_02: "terms and conditions",
        title_Hero: "Complete Workwear Solutions",
        subtitle_Hero:
          "Find essential workwear and protective gear for every job, from head protection to durable footwear. Safety made simple with Kompakt.",
        subtitle_Hero_01:
          "Ensure total protection on the job with our range of quality safety equipment—workwear, gloves, helmets, and more.",
        subtitle_Hero_02:
          "Discover reliable protective clothing and accessories for hands, feet, eyes, and more, designed to keep you safe at work.",
        subtitle_Hero_03:
          "Work and play safely with our range of protection for all ages. Quality gear for everyone, every need.",
        title_Hero_01: "Safety from Head to Toe",
        title_Hero_02: "Trusted Protection Gear",
        title_Hero_03: "Kids and Adult Safety Essentials",
        show_more: "Show more",
      },
    },
    de: {
      translation: {
        Workwear: "Arbeitskleidung",
        Trousers: "Hosen",
        Jackets: "Jacken",
        Waistcoats: "Westen",
        Pullovers: "Pullover",
        Shirts: "Hemden",
        Aprons: "Schürzen",
        Accessories: "Zubehör",
        Protective_clothing: "Schutzkleidung",
        Protective_aprons: "Schutzschürzen",
        Protective_overalls: "Schutzoveralls",
        High_visibility_clothing: "Hochsichtbare Kleidung",
        Hand_arm_protection: "Hand- und Armschutz",
        Arm_protection: "Armschutz",
        Antistatic_gloves: "Antistatische Handschuhe",
        Chemical_protective_gloves: "Chemikalienschutz-Handschuhe",
        Mechanic_s_gloves: "Mechaniker-Handschuhe",
        Cut_protection_gloves: "Schnittschutz-Handschuhe",
        Welding_gloves: "Schweißhandschuhe",
        Puncture_protection_gloves: "Stichschutz-Handschuhe",
        Winter_gloves: "Winterhandschuhe",
        Leather_gloves: "Lederhandschuhe",
        Nitrile_gloves: "Nitril-Handschuhe",
        Knitted_gloves: "Strickhandschuhe",
        PVC_gloves: "PVC-Handschuhe",
        Cotton_gloves: "Baumwollhandschuhe",
        Disposable_gloves: "Einweg-Handschuhe",
        Foot_protection: "Fußschutz",
        Clogs: "Holzschuhe",
        Low_shoes: "Niedrige Schuhe",
        Sandals: "Sandalen",
        Slippers: "Pantoffeln",
        Boots: "Stiefel",
        Winter_boots: "Winterstiefel",
        Respiratory_protection: "Atemschutz",
        Respirator_masks: "Atemschutzmasken",
        Eye_protection: "Augenschutz",
        Ultraviolet_filter: "UV-Schutzfilter",
        Sunglare_filter: "Sonnenglastfilter",
        Head_protection: "Kopfschutz",
        Safety_helmets: "Sicherheitshelme",
        Kids_selection: "Kinder-Auswahl",
        Garments: "Kleidung",
        Hand_protection: "Handschutz",
        Product: "Produkt",
        Service: "Service",
        "Protective aprons": "Schutzschürzen",
        Inspiration: "Inspiration",
        Catalogue: "Katalog",
        Category_brochures: "Kategoriebroschüren",
        Guidelines: "Richtlinien",
        Resistance_lists: "Widerstandslisten",
        Company: "Unternehmen",
        Field_service: "Feldservice",
        International_partners: "Internationale Partner",
        Quality_management: "Qualitätsmanagement",
        Sustainability: "Nachhaltigkeit",
        Logistics: "Logistik",
        Logout: "Ausloggen",
        Login: "Einloggen",
        Find_us: "Finden Sie uns",
        Call_us: "Rufen Sie uns an",
        Mail_us: "Mailen Sie uns",
        footer_text:
          "bietet hochwertige Arbeitskleidung und Schutzausrüstung, einschließlich Kleidung, Hand-, Fuß-, Augen- und Atemschutz. Sie bieten Beratung, Schulung und Verkaufsunterstützung und konzentrieren sich auf Sicherheit und Einhaltung für verschiedene Branchen.",
        Follow_us: "Folge uns",
        Aboutus: "Über uns",
        Information: "Information",
        Impressum: "Impressum",
        Datenschutz: "Datenschutz",
        Copyright: "Copyright",
        allri: "Alle Rechte vorbehalten",
        PrivacyPolicy: "Datenschutz-Bestimmungen",
        tc: "Geschäftsbedingungen",
        inspiration_01: "Inspiration bei Kompakt Arbeitsschutz",
        inspiration_02:
          "Seit 2012 setzt sich Kompakt Arbeitsschutz dafür ein, Sicherheit, Komfort und Zuverlässigkeit in jedem Stück Schutzausrüstung zu bieten, das wir anbieten. Unser Engagement für Qualität und Innovation inspiriert uns, sicherzustellen, dass sich jeder Arbeiter sicher und gestärkt bei der Arbeit fühlt.",
        inspiration_03: "Vom Zweck getrieben",
        inspiration_04:
          "Wir glauben, dass Schutzkleidung nicht nur schützen, sondern auch Produktivität und Komfort unterstützen sollte. Jedes von uns entworfene Element, von Arbeitskleidung bis zu spezialisierter Schutzkleidung, wird mit hohen Standards gefertigt, um sicherzustellen, dass unsere Kunden in allen Arbeitsbedingungen geschützt bleiben.",
        inspiration_05: "Innovation für jeden Bedarf",
        inspiration_06:
          "Bei Kompakt Arbeitsschutz suchen wir ständig nach Fortschritten in der Sicherheitstechnologie, um Ihnen fortschrittliche Lösungen für Hand-, Arm-, Fuß-, Atem- und Augenschutz zu bieten. Wir verstehen, dass Sicherheit jeden Winkel abdecken muss, und sind inspiriert von unserem Engagement für umfassenden Schutz.",
        inspiration_07: "Ermächtigung für alle Altersgruppen",
        inspiration_08:
          "Von Erwachsenen bis zu unserer speziellen Auswahl für Kinder sorgen unsere Produkte dafür, dass Sicherheit frühzeitig beginnt. Wir möchten, dass jeder – unabhängig von Alter oder Rolle – Zugang zum besten verfügbaren Schutz hat.",
        inspiration_09:
          "Lassen Sie sich von uns inspirieren, mit Vertrauen zu arbeiten. Bei Kompakt Arbeitsschutz sind wir hier, um Sie bei jedem Schritt zu unterstützen.",
        Fieldservice_01: "Vor-Ort-Service",
        Fieldservice_02:
          "Unser Vor-Ort-Service bietet Ihnen direkte Unterstützung, um die beste Passform, Komfort und Funktionalität unserer Produkte für Ihre Belegschaft sicherzustellen.",
        Fieldservice_03:
          "Wir bieten Beratungen und Anproben direkt bei Ihnen vor Ort an, um Ihre Belegschaft mit der richtigen Schutzkleidung und den passenden Accessoires auszustatten.",
        Fieldservice_04:
          "Von der Vermessung über Produktauswahl bis hin zur Anpassung sind unsere Experten für Ihre spezifischen Sicherheitsanforderungen da.",
        Fieldservice_05: "Alle Rechte vorbehalten.",
        Phone: "Telefon",
        aboutus_01:
          "Ihr vertrauenswürdiger Partner für hochwertige Arbeitskleidung und Schutzlösungen. Seit unserer Gründung 2012 in Düren, Deutschland, haben wir uns darauf spezialisiert, hochwertige Sicherheitsbekleidung und Schutzausrüstung anzubieten, die auf verschiedene Branchen zugeschnitten ist. Unsere Produkte sind sorgfältig ausgewählt, um Haltbarkeit, Komfort und optimalen Schutz zu gewährleisten und Ihnen zu helfen, jeden Tag sicher zu arbeiten.",
        aboutus_02: "Willkommen bei",
        aboutus_03:
          "Bei Kompakt Arbeitsschutz haben wir Ihre Sicherheit im Blick. Unser umfangreiches Sortiment umfasst Arbeitskleidung, Schutzkleidung, Hand- und Armschutz, Fußschutz, Atem- und Augenschutz sowie Kopfbedeckungen. Wir bieten sogar eine eigene Kinderkollektion, damit die ganze Familie von zuverlässiger, sicherer Arbeitskleidung profitieren kann.",
        aboutus_04:
          "Gestützt auf jahrelange Erfahrung und Engagement für Exzellenz, steht Ihnen unser Team mit fachkundiger Beratung und herausragendem Kundenservice zur Seite. Ob Sie ein einzelner Arbeiter oder ein großes Unternehmen sind, wir haben die Expertise und die Produkte, um Ihre spezifischen Anforderungen zu erfüllen.",
        aboutus_05: "Kontaktieren Sie uns gerne unter",
        aboutus_06: "oder rufen Sie uns an unter",
        aboutus_07:
          "Vielen Dank, dass Sie Kompakt Arbeitsschutz gewählt haben, wo Sicherheit auf Zuverlässigkeit trifft.",
        datenschutz_01: "Datenschutzrichtlinie",
        datenschutz_02:
          "Bei Kompakt Arbeitsschutz haben die Sicherheit und der Schutz Ihrer persönlichen Informationen oberste Priorität. Wir halten uns an die DSGVO und andere relevante Datenschutzgesetze, um sicherzustellen, dass Ihre Daten verantwortungsbewusst und transparent behandelt werden.",
        datenschutz_03: "Datenerhebung",
        datenschutz_04:
          "Wir erheben personenbezogene Daten ausschließlich zum Zweck der Auftragsabwicklung, Bereitstellung von Kundensupport und Verbesserung Ihres Einkaufserlebnisses. Dazu gehören:",
        datenschutz_05: "Kontaktinformationen",
        datenschutz_06: "wie Ihr Name, Adresse und Telefonnummer.",
        datenschutz_07: "Zahlungsinformationen",
        datenschutz_08:
          "sicher verarbeitet durch vertrauenswürdige Zahlungsgateways.",
        datenschutz_09: "Präferenzen und Feedback",
        datenschutz_10:
          "zur Verbesserung Ihres Erlebnisses und zur Anpassung von Diensten.",
        datenschutz_11: "Verwendung Ihrer Daten",
        datenschutz_12: "Ihre Informationen werden verwendet, um:",
        datenschutz_13: "Aufträge zu erfüllen und zu liefern",
        datenschutz_14:
          "Bestellaktualisierungen und relevante Informationen zu kommunizieren",
        datenschutz_15:
          "Ihre Erfahrung zu personalisieren und geeignete Produkte zu empfehlen (wenn die Zustimmung erteilt wird)",
        datenschutz_16: "Datensicherheit",
        datenschutz_17:
          "Kompakt Arbeitsschutz verwendet fortschrittliche Verschlüsselungs- und Sicherheitsmaßnahmen, um Ihre Informationen zu schützen. Unsere Systeme sind darauf ausgelegt, unbefugten Zugriff zu verhindern, und unsere Zahlungsabwicklungspartner sind PCI-DSS-konform, um die Sicherheit Ihrer Zahlungsdaten zu gewährleisten.",
        datenschutz_18: "Drittanbieterfreigabe",
        datenschutz_19:
          "Wir teilen Ihre Daten nur mit wesentlichen Drittanbietern, wie Zahlungsabwicklern und Lieferpartnern, ausschließlich zur",
        datenschutz_20: "Ihre Rechte",
        datenschutz_21:
          "Sie haben volle Rechte über Ihre persönlichen Informationen, einschließlich der Möglichkeit, auf Ihre Daten zuzugreifen, sie zu aktualisieren oder zu löschen. Um diese Rechte auszuüben, kontaktieren Sie uns bitte unter",
        datenschutz_22: "Cookies und Tracking",
        datenschutz_23:
          "Um die Funktionalität und Benutzererfahrung der Website zu verbessern, verwenden wir Cookies. Sie können Ihre Cookie-Einstellungen direkt in den Browsereinstellungen anpassen.",
        datenschutz_24: "Kontakt",
        datenschutz_25:
          "Bei Fragen oder Bedenken zum Datenschutz kontaktieren Sie uns bitte:",
        PrivacyPolicy_01:
          "Willkommen bei Kompakt Arbeitsschutz! Wir schätzen Ihre Privatsphäre und sind verpflichtet, Ihre persönlichen Daten zu schützen. Diese Datenschutzerklärung erklärt, wie wir Ihre Daten sammeln, verwenden und schützen, wenn Sie unsere Website besuchen oder einen Kauf tätigen.",
        PrivacyPolicy_02:
          "Wir sammeln Informationen, um Ihnen den bestmöglichen Service zu bieten. Dazu gehören:",
        PrivacyPolicy_03:
          "Persönliche Informationen: Ihr Name, Ihre E-Mail-Adresse, Telefonnummer, Versandadresse und Zahlungsdetails, wenn Sie einen Kauf tätigen oder ein Konto erstellen.",
        PrivacyPolicy_04:
          "Technische Informationen: Zum Beispiel Ihre IP-Adresse, Browsertyp, Gerätedetails und Website-Nutzungsdaten, die durch Cookies und ähnliche Technologien gesammelt werden.",
        PrivacyPolicy_05:
          "Kaufhistorie: Aufzeichnungen vergangener Bestellungen zur Verbesserung des Kundensupports und zur Empfehlung relevanter Produkte.",
        PrivacyPolicy_06:
          "Wir verwenden Ihre Daten zu verschiedenen Zwecken, einschließlich:",
        PrivacyPolicy_07:
          "Bearbeitung und Lieferung von Bestellungen, einschließlich der Verwaltung von Rücksendungen und Umtausch.",
        PrivacyPolicy_08:
          "Kommunikation mit Ihnen über Bestellungen, Werbeaktionen und Kundenanfragen.",
        PrivacyPolicy_09:
          "Verbesserung unserer Website und Dienstleistungen für eine bessere Benutzererfahrung.",
        PrivacyPolicy_10:
          "Betrugsprävention, Transaktionssicherheit und Einhaltung gesetzlicher Anforderungen.",
        PrivacyPolicy_11:
          "Wir geben Ihre Informationen nur dann weiter, wenn dies erforderlich ist, einschließlich an:",
        PrivacyPolicy_12:
          "Dienstleister: z.B. Zahlungsabwickler und Versandpartner, um Bestellungen zu erfüllen und Dienstleistungen zu erbringen.",
        PrivacyPolicy_13:
          "Rechtskonformität: Um gesetzliche Verpflichtungen zu erfüllen oder unsere Rechte, unser Eigentum oder unsere Sicherheit zu schützen.",
        PrivacyPolicy_14:
          "Unternehmensübertragungen: Bei Fusionen, Übernahmen oder Verkäufen von Vermögenswerten.",
        PrivacyPolicy_15:
          "Wir verwenden Cookies, um Ihre Erfahrung zu verbessern, Präferenzen zu speichern und den Website-Verkehr zu analysieren. Sie können Cookies über Ihre Browsereinstellungen deaktivieren, aber einige Funktionen könnten dadurch eingeschränkt sein.",
        PrivacyPolicy_16:
          "Wir ergreifen Maßnahmen, um Ihre Informationen zu schützen. Sensible Daten wie Zahlungsdetails werden verschlüsselt. Beachten Sie jedoch, dass keine Methode der Internetübertragung vollständig sicher ist.",
        PrivacyPolicy_17:
          "Je nach Standort haben Sie möglicherweise Rechte in Bezug auf Ihre Daten, wie zum Beispiel:",
        PrivacyPolicy_18:
          "Zugriff, Korrektur oder Löschung Ihrer persönlichen Daten.",
        PrivacyPolicy_19:
          "Einschränkung oder Widerspruch gegen bestimmte Verarbeitungstätigkeiten.",
        PrivacyPolicy_20:
          "Widerruf der Einwilligung, wenn die Verarbeitung auf Einwilligung beruht.",
        PrivacyPolicy_21:
          "Wir können diese Datenschutzerklärung von Zeit zu Zeit aktualisieren, um Änderungen in unseren Praktiken oder aus rechtlichen Gründen widerzuspiegeln. Die aktualisierte Richtlinie wird hier mit dem Gültigkeitsdatum veröffentlicht.",
        PrivacyPolicy_22:
          "Wenn Sie Fragen oder Bedenken zu dieser Datenschutzerklärung haben, kontaktieren Sie uns bitte unter:",
        PrivacyPolicy23: "Gültigkeitsdatum",
        Introduction: "Einführung",
        PrivacyPolicy_h2_2: "2. Informationen, die wir sammeln",
        PrivacyPolicy_h2_3: "3. Wie wir Ihre Informationen verwenden",
        PrivacyPolicy_h2_4: "4. Weitergabe Ihrer Informationen",
        PrivacyPolicy_h2_5: "5. Cookies und Tracking",
        PrivacyPolicy_h2_6: "6. Datensicherheit",
        PrivacyPolicy_h2_7: "7. Ihre Rechte",
        PrivacyPolicy_h2_8: "8. Änderungen an dieser Richtlinie",
        PrivacyPolicy_h2_9: "9. Kontaktieren Sie uns",
        PrivacyPolicy24:
          "Für alle datenbezogenen Anfragen kontaktieren Sie uns bitte unter",
        SIGN_IN: "Anmelden",
        email: "Ihre E-Mail-Adresse",
        memeber: "Haben Sie bereits ein Konto?",
        Terms_01: "Ich stimme den",
        Terms_02: "Geschäftsbedingungen zu",
        title_Hero: "Komplette Arbeitskleidungslösungen",
        subtitle_Hero:
          "Finden Sie essentielle Arbeitskleidung und Schutzausrüstung für jeden Job, von Kopfschutz bis zu strapazierfähigem Schuhwerk. Sicherheit einfach gemacht mit Kompakt.",
        subtitle_Hero_01:
          "Sorgen Sie für umfassenden Schutz am Arbeitsplatz mit unserer hochwertigen Schutzausrüstung – Arbeitskleidung, Handschuhe, Helme und mehr.",
        subtitle_Hero_02:
          "Entdecken Sie zuverlässige Schutzkleidung und Zubehör für Hände, Füße, Augen und mehr, entwickelt, um Sie bei der Arbeit sicher zu halten.",
        subtitle_Hero_03:
          "Arbeiten und spielen Sie sicher mit unserer Schutzkleidung für alle Altersgruppen. Qualitätsausrüstung für jeden und jeden Bedarf.",
        title_Hero_01: "Sicherheit von Kopf bis Fuß",
        title_Hero_02: "Vertrauenswürdige Schutzausrüstung",
        title_Hero_03: "Kinder- und Erwachsenensicherheitsessentials",
        show_more: "Mehr anzeigen",
      },
    },
    sq: {
      translation: {
        Workwear: "Veshja e punës",
        Trousers: "Pantallonat",
        Jackets: "Xhaketa",
        Waistcoats: "Këmishat",
        Pullovers: "Pulloverë",
        Shirts: "Këmishat",
        Aprons: "Përparimet",
        Accessories: "Aksesore",
        Protective_clothing: "Veshje mbrojtëse",
        Protective_aprons: "Përparimet mbrojtëse",
        Protective_overalls: "Përgjithshmëria mbrojtëse",
        High_visibility_clothing: "Veshje e dukshme",
        Hand_arm_protection: "Mbrojtja e dorës dhe krahut",
        Arm_protection: "Mbrojtja e krahut",
        Antistatic_gloves: "Dorëza antistatike",
        Chemical_protective_gloves: "Dorëza mbrojtëse kundër kemikaleve",
        Mechanic_s_gloves: "Dorëza për mekanikë",
        Cut_protection_gloves: "Dorëza kundër prerjeve",
        Welding_gloves: "Dorëza për rrasë",
        Puncture_protection_gloves: "Dorëza kundër shpuarjes",
        Winter_gloves: "Dorëza dimri",
        Leather_gloves: "Dorëza lëkure",
        Nitrile_gloves: "Dorëza nitrile",
        Knitted_gloves: "Dorëza të thurura",
        PVC_gloves: "Dorëza PVC",
        Cotton_gloves: "Dorëza pambuku",
        Disposable_gloves: "Dorëza njëherësh",
        Foot_protection: "Mbrojtja e këmbëve",
        Clogs: "Këpucë druri",
        Low_shoes: "Këpucë të ulëta",
        Sandals: "Sandale",
        Slippers: "Pantofla",
        Boots: "Çizme",
        Winter_boots: "Çizme dimri",
        Respiratory_protection: "Mbrojtja e frymëmarrjes",
        Respirator_masks: "Maska për frymëmarrje",
        Eye_protection: "Mbrojtja e syve",
        Ultraviolet_filter: "Filtro UV",
        Sunglare_filter: "Filtro i dritës së diellit",
        Head_protection: "Mbrojtja e kokës",
        Safety_helmets: "Kaska sigurie",
        Kids_selection: "Zgjedhja për fëmijë",
        Garments: "Veshje",
        Hand_protection: "Mbrojtja e dorës",
        Product: "Produkti",
        Service: "Shërbimi",
        "Protective aprons": "Përparimet mbrojtëse",
        Inspiration: "Inspirimi",
        Catalogue: "Katalog",
        Category_brochures: "Broshura kategorike",
        Guidelines: "Udhëzime",
        Resistance_lists: "Listat e rezistencës",
        Company: "Kompania",
        Field_service: "Shërbimi në fushë",
        International_partners: "Partnerët ndërkombëtarë",
        Quality_management: "Menaxhimi i cilësisë",
        Sustainability: "Qëndrueshmëria",
        Logistics: "Logjistika",
        Logout: "Ç'kycu",
        Login: "Hyrje",
        Find_us: "Na gjeni",
        Call_us: "Na telefononi",
        Mail_us: "Na dërgoni një email",
        footer_text:
          "ofron veshje të punës dhe pajisje mbrojtëse të cilësisë së lartë, duke përfshirë veshje, mbrojtje të dorës, këmbëve, syve dhe frymëmarrjes. Ata ofrojnë këshillim, trajnim dhe mbështetje shitjesh, duke u fokusuar në sigurinë dhe përputhshmërinë për industri të ndryshme.",
        Follow_us: "Na ndiqni",
        Aboutus: "Rreth nesh",
        Information: "Informacion",
        Impressum: "Imprint",
        Datenschutz: "Mbrojtja e të dhënave",
        Copyright: "Të drejtat autoriale",
        allri: "Të gjitha të drejtat e rezervuara",
        PrivacyPolicy: "Politika e privatësisë",
        tc: "Kushtet e përdorimit",
        inspiration_01: "Inspirimi në Kompakt Arbeitsschutz",
        inspiration_02:
          "Nga viti 2012, Kompakt Arbeitsschutz është angazhuar për të siguruar siguri, komoditet dhe besueshmëri në çdo pjesë të pajisjeve mbrojtëse që ofrojmë. Përkushtimi ynë për cilësi dhe inovacion na frymëzon të sigurojmë që çdo punëtor të ndjehet i sigurt dhe i fuqizuar në punë.",
        inspiration_03: "Drejtuar nga qëllimi",
        inspiration_04:
          "Ne besojmë që veshja e punës mbrojtëse nuk duhet vetëm të sigurojë por edhe të mbështesë produktivitetin dhe komoditetin. Çdo artikull që dizajnojmë, nga veshja e punës në veshjet mbrojtëse të specializuara, është i përpunuar me standarde të larta, duke siguruar që klientët tanë të mbeten të mbrojtur në të gjitha kushtet e punës.",
        inspiration_05: "Inovacion për çdo nevojë",
        inspiration_06:
          "Në Kompakt Arbeitsschutz, ne vazhdimisht kërkojmë përparime në teknologjinë e sigurisë për t'ju sjellë zgjidhje të avancuara për mbrojtjen e duarve, krahëve, këmbëve, frymëmarrjes dhe syve. Ne e kuptojmë që siguria do të thotë mbulim i çdo këndi dhe jemi të frymëzuar nga përkushtimi ynë për të ofruar mbrojtje të gjithanshme.",
        inspiration_07: "Fuqizimi për të gjitha moshat",
        inspiration_08:
          "Nga të rriturit deri te përzgjedhja jonë e veçantë për fëmijë, produktet tona sigurojnë që siguria të fillojë herët. Ne duam që të gjithë – pavarësisht nga mosha ose roli i tyre – të kenë qasje në mbrojtjen më të mirë të disponueshme.",
        inspiration_09:
          "Na lejoni të ju frymëzojmë për të punuar me besim. Në Kompakt Arbeitsschutz, ne jemi këtu për t'ju mbështetur në çdo hap.",
        Fieldservice_01: "Shërbimi në terren",
        Fieldservice_02:
          "Ekipi ynë i Shërbimit në Terren ofron mbështetje në vend për të siguruar përshtatjen më të mirë, rehati dhe funksionalitet të produkteve tona për stafin tuaj.",
        Fieldservice_03:
          "Ne ofrojmë konsultime dhe matje direkt në vendndodhjen tuaj, duke e bërë më të lehtë pajisjen e stafit tuaj me veshje dhe aksesorë mbrojtës të përshtatshëm.",
        Fieldservice_04:
          "Nga matjet, përzgjedhja e produkteve dhe personalizimi, ekspertët tanë janë këtu për të mbështetur nevojat tuaja specifike për siguri.",
        Fieldservice_05: "Të gjitha të drejtat e rezervuara.",
        Phone: "Telefoni",
        aboutus_01:
          "partneri juaj i besueshëm për veshje të punës dhe zgjidhje mbrojtëse. Që nga themelimi ynë në vitin 2012 në Düren, Gjermani, kemi qenë të përkushtuar për ofrimin e rrobave të sigurisë të cilësisë së lartë dhe pajisjeve mbrojtëse të përshtatura për industri të ndryshme. Produktet tona janë kujdesosur me kujdes për të siguruar qëndrueshmëri, komoditet dhe mbrojtje optimale, duke ju ndihmuar të qëndroni të sigurt në punë, çdo ditë.",
        aboutus_02: "Mirë se vini te",
        aboutus_03:
          "Në Kompakt Arbeitsschutz, ne e kemi prioritet sigurinë tuaj. Gama jonë e gjerë përfshin veshje të punës, veshje mbrojtëse, mbrojtje të dorës dhe krahut, mbrojtje të këmbëve, frymëmarrje dhe mbrojtje të syve, dhe kapakë koke. Ne edhe ofrojmë një koleksion të veçantë për fëmijët, kështu që gjithë familja mund të përfitojë nga veshjet e punës të besueshme dhe të sigurta.",
        aboutus_04:
          "Drejtuar nga vjetëritë e përvojës dhe një përkushtim për shkëlqimin, ekipi ynë është këtu për t'ju mbështetur me udhëzime eksperte dhe shërbim klienti të shkëlqyer. Pavarësisht nëse jeni një punëtor individual ose një ndërmarrje e madhe, ne kemi ekspertizën dhe produktet për të përmbushur nevojat tuaja specifike.",
        aboutus_05: "Mos hezitoni të na kontaktoni në",
        aboutus_06: "ose na telefononi në",
        aboutus_07:
          "Faleminderit që zgjodhët Kompakt Arbeitsschutz, ku siguria takon me besueshmërinë.",
        datenschutz_01: "Politika e mbrojtjes së të dhënave",
        datenschutz_02:
          "Në Kompakt Arbeitsschutz, ne e kemi prioritet sigurinë dhe privatësinë e të dhënave tuaja personale. Ne respektojmë GDPR dhe ligjet e tjera relevante për mbrojtjen e të dhënave për të siguruar që të dhënat tuaja trajtohen me përgjegjësi dhe transparencë.",
        datenschutz_03: "Mbledhja e të dhënave",
        datenschutz_04:
          "Ne mbledhim informacione personale vetëm për qëllimin e procesimit të porosive, ofrimit të mbështetjes së klientëve dhe përmirësimit të përvojës së blerjes suaj. Kjo mund të përfshijë:",
        datenschutz_05: "Informacionet e kontaktit",
        datenschutz_06: "si emri juaj, adresa dhe numri i telefonit.",
        datenschutz_07: "Informacionet e pagesës",
        datenschutz_08: "të siguruara përmes portave të sigurta të pagesës.",
        datenschutz_09: "Preferencat dhe komentet",
        datenschutz_10:
          "për të përmirësuar përvojën tuaj dhe për të përshtatur shërbimet.",
        datenschutz_11: "Përdorimi i të dhënave tuaja",
        datenschutz_12: "Informacionet tuaja përdoren për të:",
        datenschutz_13: "Plotësuar dhe dorëzuar porositë",
        datenschutz_14:
          "Komunikoni përditësime të porosisë dhe informacione relevante",
        datenschutz_15:
          "Personalizoni përvojën tuaj dhe rekomandoni produkte të përshtatshme (nëse jepet miratimi)",
        datenschutz_16: "Siguria e të dhënave",
        datenschutz_17:
          "Kompakt Arbeitsschutz përdor enkriptim të avancuar dhe masa sigurie për të mbrojtur të dhënat tuaja. Sistemet tona janë projektuar për të parandaluar qasjen e paautorizuar, dhe partnerët tanë për procesimin e pagesave janë të përputhshëm me PCI-DSS për të siguruar që të dhënat tuaja të pagesës janë të sigurta.",
        datenschutz_18: "Ndarja e palëve të treta",
        datenschutz_19:
          "Ne ndajmë të dhënat tuaja vetëm me palët e treta të nevojshme, si procesorët e pagesave dhe partnerët e dorëzimit, vetëm për të përfunduar transaksionet tuaja. Ne kurrë nuk i shesim ose i abuzojmë me të dhënat tuaja.",
        datenschutz_20: "Të drejtat tuaja",
        datenschutz_21:
          "Ju keni të drejta të plota mbi të dhënat tuaja personale, duke përfshirë aftësinë për të qasur, përditësuar ose fshirë të dhënat tuaja. Për të ushtruar këto të drejta, ju lutemi na kontaktoni në",
        datenschutz_22: "Cookies dhe ndjekja",
        datenschutz_23:
          "Për të përmirësuar funksionalitetin e faqes dhe përvojën e përdoruesit, ne përdorim cookies. Ju mund të rregulloni preferencat tuaja të cookies drejtpërdrejt në cilësimet e shfletuesit tuaj.",
        datenschutz_24: "Kontakti",
        datenschutz_25:
          "Për pyetje ose shqetësime në lidhje me mbrojtjen e të dhënave, ju lutemi na kontaktoni:",
        PrivacyPolicy_01:
          "Mirë se vini në Kompakt Arbeitsschutz! Ne vlerësojmë privatësinë tuaj dhe jemi të përkushtuar për të mbrojtur informacionin tuaj personal. Kjo Politikë e Privatësisë shpjegon se si ne mbledhim, përdorim dhe mbrojmë të dhënat tuaja kur vizitoni ose bëni një blerje në faqen tonë të internetit.",
        PrivacyPolicy_02:
          "Ne mbledhim informacion për t'ju ofruar shërbimin më të mirë të mundshëm. Kjo përfshin:",
        PrivacyPolicy_03:
          "Informacion Personal: Emri, adresa e email-it, numri i telefonit, adresa e dërgimit dhe detajet e pagesës kur bëni një blerje ose krijoni një llogari.",
        PrivacyPolicy_04:
          "Informacion Teknik: Të tilla si adresa juaj IP, lloji i shfletuesit, detajet e pajisjes dhe të dhënat e përdorimit të faqes së internetit, të mbledhura përmes cookies dhe teknologjive të ngjashme.",
        PrivacyPolicy_05:
          "Historia e Blerjeve: Regjistrimet e porosive të kaluara për të përmirësuar mbështetjen e klientit dhe për të rekomanduar produkte të rëndësishme.",
        PrivacyPolicy_06:
          "Ne përdorim të dhënat tuaja për qëllime të ndryshme, duke përfshirë:",
        PrivacyPolicy_07:
          "Përpunimi dhe dorëzimi i porosive, duke përfshirë menaxhimin e kthimeve dhe shkëmbimeve.",
        PrivacyPolicy_08:
          "Komunikimi me ju në lidhje me porositë, promocionet dhe pyetjet e klientëve.",
        PrivacyPolicy_09:
          "Përmirësimi i faqes sonë të internetit dhe shërbimeve për një përvojë më të mirë përdoruesi.",
        PrivacyPolicy_10:
          "Parandalimi i mashtrimit, sigurimi i transaksioneve dhe respektimi i kërkesave ligjore.",
        PrivacyPolicy_11:
          "Ne ndajmë informacionin tuaj vetëm kur është e nevojshme, duke përfshirë me:",
        PrivacyPolicy_12:
          "Ofruesit e Shërbimeve: Si përpunuesit e pagesave dhe partnerët e dërgesës për të përmbushur porositë dhe për të ofruar shërbime.",
        PrivacyPolicy_13:
          "Përputhshmëria ligjore: Për të përmbushur detyrimet ligjore ose për të mbrojtur të drejtat, pronën ose sigurinë tonë.",
        PrivacyPolicy_14:
          "Transferimet e Biznesit: Në rast të bashkimeve, blerjeve ose shitjeve të aseteve.",
        PrivacyPolicy_15:
          "Ne përdorim cookies për të përmirësuar përvojën tuaj, për të ruajtur preferencat dhe për të analizuar trafikun e faqes. Ju mund të çaktivizoni cookies përmes cilësimeve të shfletuesit tuaj, por disa funksione mund të jenë të kufizuara si rezultat.",
        PrivacyPolicy_16:
          "Ne marrim masa për të siguruar informacionin tuaj. Të dhënat e ndjeshme, si detajet e pagesës, janë të koduara. Megjithatë, vini re se asnjë metodë e transmetimit në internet nuk është plotësisht e sigurt.",
        PrivacyPolicy_17:
          "Në varësi të vendndodhjes tuaj, ju mund të keni të drejta në lidhje me të dhënat tuaja, si për shembull:",
        PrivacyPolicy_18:
          "Qasja, korrigjimi ose fshirja e informacionit tuaj personal.",
        PrivacyPolicy_19:
          "Kufizimi ose kundërshtimi i aktiviteteve specifike të përpunimit.",
        PrivacyPolicy_20:
          "Tërheqja e pëlqimit kur përpunimi bazohet në pëlqim.",
        PrivacyPolicy_21:
          "Ne mund të përditësojmë këtë Politikë të Privatësisë herë pas here për të pasqyruar ndryshimet në praktikat tona ose për arsye ligjore. Politika e përditësuar do të postohet këtu me datën e hyrjes në fuqi.",
        PrivacyPolicy_22:
          "Nëse keni ndonjë pyetje ose shqetësim në lidhje me këtë Politikë të Privatësisë, ju lutemi na kontaktoni në:",
        PrivacyPolicy23: "Data e hyrjes në fuqi",
        Introduction: "Njohje",
        PrivacyPolicy_h2_2: "2. Informacioni që mbledhim",
        PrivacyPolicy_h2_3: "3. Si e përdorim informacionin tuaj",
        PrivacyPolicy_h2_4: "4. Ndërveprimi i informacionit tuaj",
        PrivacyPolicy_h2_5: "5. Cookies dhe Gjurmimi",
        PrivacyPolicy_h2_6: "6. Siguria e të Dhënave",
        PrivacyPolicy_h2_7: "7. Të Drejtat Tuaja",
        PrivacyPolicy_h2_8: "8. Ndryshimet në këtë Politikë",
        PrivacyPolicy_h2_9: "9. Na Kontaktoni",
        PrivacyPolicy24:
          "Për çdo pyetje të lidhur me të dhënat, ju lutemi na kontaktoni në",
        SIGN_IN: "Hyrje",
        email: "Adresa juaj e emailit",
        memeber: "Keni një llogari?",
        Terms_01: "Unë pranoj",
        Terms_02: "Kushtet e përdorimit",
        title_Hero: "Zgjidhje të plota të veshjeve të punës",
        subtitle_Hero:
          "Gjeni veshje të punës dhe pajisje mbrojtëse të rëndësishme për çdo punë, nga mbrojtja e kokës deri te këpucët e forta të qëndrueshme. Siguria e thjeshtë me Kompakt.",
        subtitle_Hero_01:
          "Siguroni mbrojtje të plotë në punë me gamën tonë të pajisjeve mbrojtëse cilësore – veshje pune, doreza, helmeta dhe më shumë.",
        subtitle_Hero_02:
          "Zbuloni veshje mbrojtëse të besueshme dhe aksesorë për duart, këmbët, sytë dhe më shumë, të dizajnuara për t'ju mbrojtur në punë.",
        subtitle_Hero_03:
          "Punoni dhe luani të sigurt me gamën tonë të pajisjeve mbrojtëse për të gjitha moshat. Pajisje cilësore për të gjithë dhe për çdo nevojë.",
        title_Hero_01: "Siguria nga koka në këmbë",
        title_Hero_02: "Pajisje mbrojtëse e besueshme",
        title_Hero_03:
          "Pajisje esenciale për sigurinë e fëmijëve dhe të rriturve",
        show_more: "Shfaq më shumë",
      },
    },
  },
  lng: "de", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
