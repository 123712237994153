import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Shopcontext } from "../../context/Shopcontext";
// logo
import Logo from "../../assest/slider/kompakt_logo_red.svg";
// import { Link } from "react-router-dom";

const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState("");
  const [activeCategory, setActiveCategory] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { getTotalCartItems } = useContext(Shopcontext);

  const toggleMenu = (menu) => {
    if (activeMenu === menu) {
      setActiveMenu(""); // Close the menu if it's already open
      setActiveCategory(""); // Reset subcategories when closing
    } else {
      setActiveMenu(menu); // Open the selected menu
      setActiveCategory(""); // Reset subcategories
    }
  };

  const toggleCategory = (category) => {
    setActiveCategory(activeCategory === category ? "" : category);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="w-full bg-white text-red-500 border-x-0 border-t-0 border-b-red-500 p-4">
      {/* Navbar container */}
      <div className="flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="text-xl font-bold">
          <img src={Logo} className="w-[150px]" alt="logo" />
        </Link>

        {/* Mobile Menu button */}
        <div className="xl:hidden">
          <div className="button flex ">
            <div className="button_search flex">
              {localStorage.getItem("auth-token") ? (
                <button
                  type="button"
                  className="logout"
                  onClick={() => {
                    localStorage.removeItem("auth-token");
                    window.location.replace("/");
                  }}
                >
                  Logout
                </button>
              ) : (
                <Link
                  to="/login"
                  className="rounded-lg mx-1 bg-red-500 px-3 py-2 font-semibold  text-white flex justify-center items-center hover:bg-red-400 ease-in-out transition "
                >
                  Login
                </Link>
              )}
              <Link
                to="/cart"
                className="rounded-lg -mx-5 px-6 py-2 font-semibold text-red text-2xl flex justify-center items-center hover:text-red-400 ease-in-out transition"
              >
                <i className="pi pi-shopping-cart"></i>
              </Link>

              <Link
                to="/searchbar"
                className="rounded-lg -mx-2  px-6 py-2 font-semibold text-red text-2xl flex justify-center items-center hover:text-red-400 ease-in-out transition"
              >
                <i className="pi pi-search"></i>
                <div className="nav-cart-count absolute w-[18px] h-[18px] flex justify-center align-middle mt-[-40px] ml-[-60px] rounded-xl text-xs bg-red-500 text-white">
                  {getTotalCartItems()}
                </div>
              </Link>
            </div>
            <button
              className="px-3 py-2 bg-red-500 rounded-lg  text-white"
              onClick={toggleMobileMenu}
            >
              <i
                className={isMobileMenuOpen ? "pi pi-times" : "pi pi-bars"}
              ></i>
            </button>
          </div>
        </div>

        {/* Desktop menu */}
        <div className="hidden xl:flex space-x-4">
          <button onClick={() => toggleMenu("products")}>Products</button>
          <button onClick={() => toggleMenu("inspiration")}>Inspiration</button>
          <button onClick={() => toggleMenu("company")}>Company</button>
        </div>
      </div>

      {/* Mobile menu items */}
      {isMobileMenuOpen && (
        <div className="xl:hidden mt-4 bg-white w-full  left-0 right-0 pl-3 pb-10 rounded-b-lg absolute z-50 shadow-lg">
          <button
            className="block mt-2 text-left  border border-x-0 border-t-0  border-b-red-500 w-full"
            onClick={() => toggleMenu("products")}
          >
            Products
          </button>
          {activeMenu === "products" && (
            <div className="mx-4">
              <button
                className={`block mt-2 text-left  border border-x-0 border-t-0 border-b-red-500 w-full ${
                  activeCategory === "workwear" ? "font-bold" : "font-normal"
                }`}
                onClick={() => toggleCategory("workwear")}
              >
                Workwear
              </button>
              {activeCategory === "workwear" && (
                <div className="mx-4 ">
                  <p className="  border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Trousers
                  </p>
                  <p className=" border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Jackets
                  </p>
                  <p className=" border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Waistcoats
                  </p>
                  <p className=" border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Pullovers
                  </p>
                  <p className=" border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Shirts
                  </p>
                  <p className=" border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Aprons
                  </p>
                  <p className=" border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Accessories
                  </p>
                </div>
              )}
              {/* Add more categories and subcategories here */}
              <button
                className={`block text-left  mt-2 border border-x-0 border-t-0 border-b-red-500 w-full ${
                  activeCategory === "protectiveClothing"
                    ? "font-bold"
                    : "font-normal"
                }`}
                onClick={() => toggleCategory("protectiveClothing")}
              >
                Protective Clothing
              </button>
              {activeCategory === "protectiveClothing" && (
                <div className="ml-4">
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Protective Aprons
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Protective Overalls
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    High-Visibility Clothing
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Accessories
                  </p>
                </div>
              )}

              <button
                className={`block text-left  mt-2 border border-x-0 border-t-0 border-b-red-500 w-full ${
                  activeCategory === "handArmProtection"
                    ? "font-bold"
                    : "font-normal"
                }`}
                onClick={() => toggleCategory("handArmProtection")}
              >
                Hand / Arm Protection
              </button>
              {activeCategory === "handArmProtection" && (
                <div className="ml-4">
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Arm Protection
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Antistatic Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Chemical Protective Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Mechanic's Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Cut Protection Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Welding Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Puncture Protection Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Winter Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Leather Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Nitrile Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Knitted Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    PVC Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Cotton Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Disposable Gloves
                  </p>
                  <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                    Accessories
                  </p>
                </div>
              )}
            </div>
          )}

          <button
            className={`block text-left  mt-2 border border-x-0 border-t-0 border-b-red-500 w-full ${
              activeCategory === "inspiration" ? "font-bold" : "font-normal"
            } `}
            onClick={() => toggleMenu("inspiration")}
          >
            Inspiration
          </button>
          {activeMenu === "inspiration" && (
            <div className="ml-4">
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                Catalogue
              </p>
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                Category brochures
              </p>
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                Guidelines
              </p>
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                Resistance lists
              </p>
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                Insights
              </p>
            </div>
          )}

          <button
            className={`block text-left  mt-2 border border-x-0 border-t-0 border-b-red-500 w-full ${
              activeCategory === "company" ? "font-bold" : "font-normal"
            } `}
            onClick={() => toggleMenu("company")}
          >
            Company
          </button>
          {activeMenu === "company" && (
            <div className="ml-4">
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                Field service
              </p>
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                International partners
              </p>
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                Quality management
              </p>
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                Sustainability
              </p>
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                Logistics
              </p>
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                About us
              </p>
              <p className="border border-x-0 border-t-0 border-b-red-500 w-full ">
                Careers
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Navbar;
