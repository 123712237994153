import React from "react";

import offertsimage from "../../assest/images/offerts/offerts.webp";

const Offerts = () => {
  return (
    <div className="w-full">
      <div className="offerts w-full lg:w-3/4 h-auto lg:h-40vh flex flex-col lg:flex-row m-auto px-4 lg:px-[50px] my-[60px] lg:my-[140px] bg-gradient-to-r border border-red-500 rounded-md">
        <div className="offerts-left flex-1 flex flex-col justify-center text-center lg:text-left p-4 lg:p-0">
          <h1 className="text-black text-2xl lg:text-3xl font-bold">
            Exclusive
          </h1>
          <h2 className="text-black text-2xl lg:text-3xl font-semibold mt-2 lg:mt-0">
            Make your choice and we will offer you the offers
          </h2>
          <p className="mt-2 lg:mt-4">
            Explore our exclusive selection of offers tailored just for you.
            Make your choice, and we will present you with the best deals
            available. Your satisfaction is our priority, and we’re here to
            ensure you find exactly what you’re looking for.
          </p>
          <button
            type="button"
            className="w-full lg:w-[282px] h-[50px] lg:h-[70px] rounded-xl bg-red-500 hover:bg-red-400 transition ease-linear border-none text-white text-xl lg:text-2xl font-semibold mt-4 lg:mt-[30px] cursor-pointer"
          >
            Check Now
          </button>
        </div>
        <div className="offerts-right flex-1 flex items-center lg:items-end justify-center pt-4 lg:pt-[50px]">
          <img
            src={offertsimage}
            alt="kompakt img"
            className="w-[200px] lg:w-[300px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Offerts;
