import React from "react";

const Catalogue = () => {
  return (
    <div>
      <p>Catalogue</p>
    </div>
  );
};

export default Catalogue;
