import React, { useEffect, useState } from "react";
import Item from "../Item/Item";

const Popular = () => {
  const [popularProducts, setPopularProducts] = useState([]);

  useEffect(() => {
    fetch("http://localhost:5000/api/products/popularclothing")
      .then((response) => response.json())
      .then((data) => setPopularProducts(data));
  }, []);
  return (
    <div className="popular flex flex-col items-center gap-10 min-h-screen">
      <h1 className="text-red-500 text-3xl md:text-4xl font-bold -mb-5 mt-8">
        Popular clothing
      </h1>
      <hr className="w-[200px] h-[4px] rounded-lg bg-red-500" />
      <div className="popular-item flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-10">
        {popularProducts.length > 0 ? (
          popularProducts.map((item) => (
            <Item
              key={item.id}
              id={item.id}
              name={item.name}
              description={item.description}
              new_price={item.new_price}
              old_price={item.old_price}
              image={item.images}
            />
          ))
        ) : (
          <p>No popular products available at the moment.</p>
        )}
      </div>
    </div>
  );
};

export default Popular;
