import React from "react";
import { useTranslation } from "react-i18next";

const Fieldservice = () => {
  const { t } = useTranslation();
  return (
    <div className="container-sm mx-auto px-4">
      <h2 className="text-red-500 font-bold text-2xl">
        {t("Fieldservice_01")}
      </h2>
      <hr className="border border-red-500 my-3 w-[100px]" />
      <p className="text-black font-semibold ">{t("Fieldservice_02")}</p>
      <p className="text-black font-semibold ">{t("Fieldservice_03")}</p>
      <p className="text-black font-semibold ">{t("Fieldservice_04")}</p>
      <footer>
        <p className="text-red-500 font-bold">Kompakt Arbeitsschutz</p>
        <p className="text-red-500 font-bold">
          Josef-Schregel-Str. 68, 52349 Düren, DE
        </p>
        <p className="font-bold">
          Email:{" "}
          <a
            href="mailto:info@kompakt-arbeitsschutz.de"
            className="font-bold text-red-500"
          >
            info@kompakt-arbeitsschutz.de
          </a>
        </p>
        <p className=" font-bold">
          {t("Phone")}:{" "}
          <a href="tel:+4924219590176" className="text-red-500 font-bold">
            +49 2421 9590176
          </a>
        </p>
        <p>&copy; 2012 Kompakt Arbeitsschutz. {t("Fieldservice_05")} </p>
      </footer>
    </div>
  );
};

export default Fieldservice;
