import React, { useContext } from "react";
import { Shopcontext } from "../../context/Shopcontext";
import { FaPlus } from "react-icons/fa6";

const CartItems = () => {
  const { getTotalCartAmount, allProducts, cartItems, removeFromCart, prices } =
    useContext(Shopcontext);

  return (
    <div className="cartitems my-[100px] mx-[170px]">
      <div className="cartitems-format-main grid grid-cols-[0.5fr_2fr_1fr_1fr_1fr_1fr] items-center gap-[75px] py-5 px-0 text-[#454545] text-[18px] font-semibold">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr className="h-[2px] bg-red-300 border-" />
      <div>
        {allProducts.map((e) => {
          if (cartItems[e.id] > 0) {
            const price = prices[e.id] || e.new_price; // Use price from context or fallback to default price
            return (
              <div key={e.id}>
                <div className="caritems-format text-[16px] font-medium grid grid-cols-[0.5fr_2fr_1fr_1fr_1fr_1fr] items-center gap-[75px] py-5 px-0 text-[#454545]">
                  <img src={e.images} alt="" className="h-[62px]" />
                  <p> {e.name} </p>
                  <p> ${price} </p>
                  <button className="cartitems-quantity w-[64px] h-[50px] border-2 border-red-300 bg-white">
                    {cartItems[e.id]}
                  </button>
                  <p> ${price * cartItems[e.id]} </p>
                  <FaPlus
                    className="cartitems-remove text-2xl my-0 mx-[20px] cursor-pointer rotate-45"
                    onClick={() => removeFromCart(e.id)}
                  />
                </div>
                <hr className="h-[2px] bg-red-300 border-0" />
              </div>
            );
          }
          return null;
        })}
        <div className="cartitems-down flex my-24">
          <div className="cartitems-total flex flex-col flex-1 mr-50 gap-10">
            <h1 className="text-black text-2xl font-semibold">Cart Total</h1>
            <div>
              <div className="cartitems-total-item flex justify-between py-4">
                <p>Subtotal</p>
                <p>$ {getTotalCartAmount()} </p>
              </div>
              <hr />
              <div className="cartitems-total-item flex justify-between py-4">
                <p>Shipping Free</p>
                <p>Free</p>
              </div>
              <hr />
              <div className="cartitems-total-item flex justify-between py-4">
                <h3>Total</h3>
                <h3> ${getTotalCartAmount()} </h3>
              </div>
              <button className="w-[262px] h-[58px] outline-none border-0 bg-red-500 hover:bg-red-400 transition ease-linear rounded-lg text-white text-[16px] font-semibold cursor-pointer">
                Proceed to checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItems;
