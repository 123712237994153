import React from "react";
import { MdArrowForwardIos } from "react-icons/md";

const Brendcrum = (props) => {
  const { product } = props;

  return (
    <div className="breadcrum flex items-center gap-2 text-gray-500 text-base font-semibold my-4 mx-4 md:my-6 md:mx-20 capitalize">
      Products
      <MdArrowForwardIos className="text-red-500" />
      {product.category}
      <MdArrowForwardIos className="text-red-500" />
      {product.name}
    </div>
  );
};

export default Brendcrum;
