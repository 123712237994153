import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RegisterPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/users/register", { name, email, password });
      toast.success("Registration successful!");
      navigate("/login");
    } catch (error) {
      console.error("Error registering:", error);
      toast.error("Error registering. Please try again.");
    }
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleRegister} className="form">
        <h1 className="leter">Register</h1>
        <label>
          Name:
          <input
            type="text"
            className="form_email"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            className="form_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            className="form_password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <button type="submit" className="form_button">
          Register
        </button>
      </form>
    </>
  );
}

export default RegisterPage;
