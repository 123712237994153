import React, { useContext } from "react";
import { Shopcontext } from "../context/Shopcontext";
import { useParams } from "react-router-dom";
import Brendcrum from "../components/Breadcrums/Brendcrum";
import Productdisplay from "../components/Productdisplay/Productdisplay";
import DescriptionBox from "../components/DescriptionBox/DescriptionBox";
import RelatedProducts from "../components/RelatedProducts/RelatedProducts";

function AddProductForm() {
  const { allProducts } = useContext(Shopcontext);
  const { productId } = useParams();
  const product = allProducts.find((e) => e.id === Number(productId));
  return (
    <div>
      <Brendcrum product={product} />
      <Productdisplay product={product} />
      <DescriptionBox />
      <RelatedProducts />
    </div>
  );
}

export default AddProductForm;
