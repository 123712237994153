import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";

const DescriptionBox = ({ productId }) => {
  const [description, setDescription] = useState("");
  const [reviews, setReviews] = useState([]);
  const [reviewText, setReviewText] = useState("");
  const [rating, setRating] = useState(0);
  const [user, setUser] = useState(null); // Store logged-in user information

  // Fetch the product description and reviews
  const fetchProductDetails = async () => {
    try {
      // Fetch the product description from the backend
      const productRes = await fetch(
        `http://localhost:5000/api/products/${productId}`
      );
      const productData = await productRes.json();
      setDescription(productData.description);

      // Fetch reviews for the product
      const reviewsRes = await fetch(
        `http://localhost:5000/api/reviews/${productId}`
      );
      const reviewsData = await reviewsRes.json();
      setReviews(reviewsData);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  // Handle review submission
  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      try {
        const res = await fetch("http://localhost:5000/api/reviews/add", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId,
            userId: user.id,
            review: reviewText,
            rating,
          }),
        });

        if (res.ok) {
          const newReview = { review: reviewText, rating, user: user.name };
          setReviews((prevReviews) => [...prevReviews, newReview]);
          setReviewText(""); // Clear the input field
          setRating(0); // Reset the rating
        } else {
          console.error("Error submitting review:", res.statusText);
        }
      } catch (error) {
        console.error("Error submitting review:", error);
      }
    } else {
      console.log("User must be logged in to submit a review");
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [productId]);

  useEffect(() => {
    // Check if the user is logged in (this can be customized based on your authentication system)
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    setUser(loggedInUser);
  }, []);

  return (
    <div className="descriptionbox mt-[120px] mb-[120px] mx-[170px]">
      <div className="descriptionbox-nav-navigator flex">
        <div className="descriptionbox-nav-box flex items-center justify-center text-[16px] font-semibold w-[171px] h-[70px] border border-[#d0d0d0]">
          Description
        </div>
        <div className="description-nav-box fade flex items-center justify-center text-[16px] font-semibold w-[171px] h-[70px] border border-[#d0d0d0]">
          Reviews ({reviews.length})
        </div>
      </div>

      <div className="descriptionbox-description flex flex-col gap-[25px] border border-[#d0d0d0] p-[48px] pb-[70px]">
        <p>{description}</p>
      </div>

      {/* Reviews Section */}
      <div className="reviews-section mt-8 border-t border-[#d0d0d0] pt-8">
        <h2 className="text-xl font-semibold mb-4">User Reviews</h2>
        <div>
          {reviews.map((review, index) => (
            <div key={index} className="review-item mb-4">
              <div className="review-header flex items-center gap-2">
                <FaStar className="text-yellow-500" />
                <span className="font-bold">{review.user}</span>
                <span className="text-gray-500 text-sm">
                  ({review.rating} stars)
                </span>
              </div>
              <p>{review.review}</p>
            </div>
          ))}
        </div>

        {user && (
          <div className="submit-review mt-6">
            <h3 className="text-lg font-semibold">Write a Review</h3>
            <form onSubmit={handleReviewSubmit}>
              <div className="flex items-center gap-2 mb-4">
                {[1, 2, 3, 4, 5].map((star) => (
                  <FaStar
                    key={star}
                    className={`cursor-pointer ${
                      star <= rating ? "text-yellow-500" : "text-gray-300"
                    }`}
                    onClick={() => setRating(star)}
                  />
                ))}
              </div>
              <textarea
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg"
                placeholder="Write your review here"
                rows="4"
                required
              />
              <button
                type="submit"
                className="mt-4 py-2 px-6 text-white bg-blue-500 rounded-lg"
              >
                Submit Review
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default DescriptionBox;
