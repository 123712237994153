import React, { useEffect } from "react";
import Item from "../Item/Item";

const Newcollection = () => {
  const [NewProducts, setNewProducts] = React.useState([]);

  useEffect(() => {
    fetch("http://localhost:5000/api/products/newcollection")
      .then((response) => response.json())
      .then((data) => setNewProducts(data));
  }, []);
  return (
    <div className="new-collection flex flex-col items-center gap-10 h-90vh">
      <h1 className="text-red-500 -mb-5  text-3xl my-3 font-bold md:text-4xl">
        New Collection
      </h1>
      <hr className="w-[150px]  h-[4px] md:w-[200px] md:h-[6px] rounded-lg bg-red-500" />
      <div className="collection grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-8 gap-6 md:gap-8 w-full px-4">
        {NewProducts.map((item, i) => (
          <Item key={i} id={item.id} name={item.name} image={item.images} />
        ))}
      </div>
    </div>
  );
};

export default Newcollection;
