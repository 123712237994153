import React, { useState, useContext } from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { Shopcontext } from "../../context/Shopcontext";

const Productdisplay = (props) => {
  const { product } = props;
  const { addToCart } = useContext(Shopcontext);

  // State to keep track of the currently selected image
  const [selectedImage, setSelectedImage] = useState(product.images[0]);

  // Set a limit for the number of images to display
  const maxImagesToShow = 3; // Change this value to 5 if you want to display 5 images

  const handleImageClick = (image) => {
    // Update the selected image when a thumbnail is clicked
    setSelectedImage(image);
  };

  return (
    <div className="productdisplay flex flex-col md:flex-row mx-4 md:mx-20 my-10">
      <div className="productdisplay-left flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="productdisplay-img-list flex flex-row md:flex-col gap-2">
          {/* Limit the number of images to display */}
          {product.images.slice(0, maxImagesToShow).map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Product image ${index + 1}`}
              className="border border-red-500 p-1 w-20 h-20 md:w-32 md:h-32 cursor-pointer"
              onClick={() => handleImageClick(image)} // Change main image on click
            />
          ))}
        </div>
        <div className="productdisplay-img h-auto">
          <img
            className="productdisplay-main-img w-full h-auto max-w-[600px] border border-red-500"
            src={selectedImage} // Display the selected image
            alt="Main product image"
          />
        </div>
      </div>

      <div className="productdisplay-right flex flex-col mt-6 md:mt-0 md:ml-10">
        <h1 className="text-gray-500 text-2xl md:text-4xl font-bold">
          {product.name}
        </h1>
        <div className="productdisplay-right-star flex items-center mt-3 gap-1 text-gray-800 text-base">
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStarHalfAlt />
          <p>(122)</p>
        </div>
        <div className="productdisplay-right-prices flex my-5 gap-5 text-lg md:text-2xl font-bold">
          <div className="productdisplay-right-price-old text-gray-500 line-through">
            ${product.old_price}
          </div>
          <div className="productdisplay-right-price-new text-red-500">
            ${product.new_price}
          </div>
        </div>

        <div className="productdisplay-right-description mt-6 text-gray-500 text-sm md:text-lg font-semibold">
          <p>{product.description}</p>
        </div>

        <div className="productdisplay-right-size flex my-4 gap-3 flex-wrap">
          {["S", "M", "L", "XL", "XXL"].map((size) => (
            <div
              key={size}
              className="py-2 px-4 bg-gray-50 border border-gray-300 rounded cursor-pointer hover:bg-gray-200 transition"
            >
              {size}
            </div>
          ))}
        </div>

        <button
          type="button"
          onClick={() => addToCart(product.id)}
          className="py-3 px-6 w-full max-w-xs text-base rounded-lg font-semibold text-white bg-red-500 mb-6 border-none outline-none cursor-pointer hover:bg-red-600 transition"
        >
          ADD TO CART
        </button>

        <p className="productdisplay-right-category mt-[10px] text-sm md:text-base">
          <span className="font-semibold">Category:</span> {product.category}
        </p>
        <p className="productdisplay-right-category mt-[10px] text-sm md:text-base">
          <span className="font-semibold">Subcategory:</span>{" "}
          {product.subcategory}
        </p>
      </div>
    </div>
  );
};

export default Productdisplay;
