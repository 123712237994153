import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Shopcontext } from "../context/Shopcontext";

const Searchbar = () => {
  const { allProducts } = useContext(Shopcontext); // Access allProducts from context
  const [isOpen, setIsOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Filter products when searchTerm changes
    if (allProducts && searchTerm) {
      const results = allProducts.filter((product) =>
        product.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(results);

      // Logging to check filtered products
      console.log("Filtered Products:", results);
    } else {
      setFilteredProducts([]);
    }
  }, [searchTerm, allProducts]);

  const closeSearch = () => {
    setIsOpen(false);
    navigate(-1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleProductClick = (productId) => {
    setIsOpen(false);
    navigate(`/product/${productId}`); // Use 'id' instead of '_id'
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center transition-opacity duration-300">
          <span
            className="absolute top-5 right-12 text-white text-6xl cursor-pointer"
            onClick={closeSearch}
            title="Close Overlay"
          >
            &times;
          </span>
          <div className="w-4/5 text-center relative">
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="p-4 w-4/5 text-lg border-none focus:outline-none rounded-l-lg"
              />
              <button
                type="button"
                className="w-1/5 p-4 bg-red-500 hover:bg-red-400 text-lg rounded-r-lg "
              >
                <i className="pi pi-search text-white"></i>
              </button>
            </form>

            {/* Display filtered products */}
            {filteredProducts.length > 0 && (
              <div className="absolute left-0 w-4/5 bg-white mt-2 p-2 border border-gray-300 rounded-lg shadow-lg max-h-64 overflow-y-auto">
                {filteredProducts.map((product) => (
                  <div
                    key={product._id}
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleProductClick(product.id)} // Pass 'id' for URL
                  >
                    {product.name || "Unnamed Product"}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Searchbar;
