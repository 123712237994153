import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

// logo import
import Logo from "../assest/slider/kompakt_logo_red.svg";

function LoginPage() {
  const [state, setState] = useState("Login"); // State to toggle between "Login" and "Sign up"
  const [phoneValue, setPhoneValue] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    company: "",
    phone: "",
    place: "",
    email: "",
    password: "",
  });

  // Handle phone number change
  const handlePhoneChange = (value) => {
    setPhoneValue(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value,
    }));
  };

  // Handle form data change for other fields
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Login function
  const login = async () => {
    const response = await fetch("http://localhost:5000/api/login", {
      method: "POST",
      headers: {
        Accept: "application/json", // Adjust Accept header
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const responseData = await response.json();
    if (responseData.success) {
      localStorage.setItem("auth-token", responseData.token);
      window.location.replace("/"); // Redirect after successful login
    } else {
      alert(responseData.errors);
    }
  };

  // Signup function
  const signup = async () => {
    const response = await fetch("http://localhost:5000/api/signup", {
      method: "POST",
      headers: {
        Accept: "application/json", // Ensure correct headers
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const responseData = await response.json();

    if (responseData && responseData.success) {
      localStorage.setItem("auth-token", responseData.token);
      window.location.replace("/"); // Redirect after successful signup
    } else {
      alert(responseData.message || "An unexpected error occurred.");
    }
  };

  return (
    <>
      <section className="py-4 md:py-8 bg-white">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="/"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img
              className="w-[200px] opacity-20 h-auto mr-2"
              src={Logo}
              alt="kompakt logo"
            />
          </a>
          <div className="w-full rounded-lg bg-white shadow-xl md:mt-0 sm:max-w-md xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-red-500 md:text-2xl text-center">
                {state}
              </h1>

              <form className="space-y-4 md:space-y-6">
                {/* Sign up fields */}
                {state === "Sign up" && (
                  <>
                    <input
                      value={formData.name}
                      onChange={changeHandler}
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      className="outline-none my-5 placeholder-black bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg block w-full p-2.5"
                      required
                    />
                    <input
                      value={formData.surname}
                      onChange={changeHandler}
                      type="text"
                      name="surname"
                      placeholder="Surname"
                      className="outline-none my-5 placeholder-black bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg block w-full p-2.5"
                      required
                    />
                    <input
                      value={formData.company}
                      onChange={changeHandler}
                      type="text"
                      name="company"
                      placeholder="Company"
                      className="outline-none my-5 placeholder-black bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg block w-full p-2.5"
                      required
                    />
                    <PhoneInput
                      name="phone"
                      placeholder="Enter phone number"
                      value={phoneValue}
                      onChange={handlePhoneChange}
                      className="outline-none flex my-5 placeholder-black bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg w-full p-2.5"
                    />
                    <input
                      value={formData.place}
                      onChange={changeHandler}
                      type="text"
                      name="place"
                      placeholder="Place (e.g., Germany / Düren)"
                      className="outline-none my-5 placeholder-black bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg block w-full p-2.5"
                      required
                    />
                  </>
                )}

                {/* Common login/signup fields */}
                <input
                  value={formData.email}
                  onChange={changeHandler}
                  type="email"
                  name="email"
                  className="placeholder-black bg-gray-50 border outline-none border-gray-300 text-black sm:text-sm rounded-lg block w-full p-2.5"
                  placeholder="name@kompakt-arbeitsschutz.com"
                  required
                />
                <input
                  value={formData.password}
                  onChange={changeHandler}
                  type="password"
                  name="password"
                  className="placeholder-black outline-none bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg block w-full p-2.5"
                  placeholder="••••••••"
                  required
                  autoComplete="current-password"
                />

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    state === "Login" ? login() : signup(); // Toggle between login/signup
                  }}
                  type="submit"
                  className="text-white bg-red-600 py-1.5 px-4 rounded w-full hover:bg-red-400 transition ease-linear duration-300"
                >
                  SIGN IN
                </button>

                {/* Toggle between login and sign up */}
                {state === "Sign up" ? (
                  <p className="text-sm text-center text-black">
                    Already have an account?{" "}
                    <Link
                      onClick={(e) => {
                        e.preventDefault(); // Prevent link default behavior
                        setState("Login");
                      }}
                      className="font-medium text-red-600 hover:underline"
                    >
                      Login Here
                    </Link>
                  </p>
                ) : (
                  <p className="text-sm text-center text-black">
                    Not a member yet?{" "}
                    <Link
                      onClick={(e) => {
                        e.preventDefault(); // Prevent link default behavior
                        setState("Sign up");
                      }}
                      className="font-medium text-red-600 hover:underline"
                    >
                      Sign up
                    </Link>
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginPage;
