import React from "react";

import logokompakt from "../../assest/images/newletter/kompakt_logo_red.png";

const Newletter = () => {
  return (
    <div className="newletter w-full max-w-3xl h-auto flex flex-col items-center justify-center m-auto p-10 my-10 gap-3 border border-red-500 rounded-2xl">
      <h1 className="text-black text-3xl md:text-4xl font-bold text-center">
        Get new Exclusive offers on your email
      </h1>
      <p className="text-black text-lg md:text-2xl text-center">
        Subscribe to our newsletter and stay updated
      </p>
      <div className="flex flex-col md:flex-row justify-between bg-white w-full rounded-md border border-red-500">
        <input
          type="email"
          placeholder="Enter your email"
          className="w-full md:w-[500px] ml-0 md:ml-5 border-none outline-none text-black text-lg md:text-2xl p-2"
        />
        <button
          type="button"
          className="w-full md:w-[210px] h-[70px] bg-red-500 rounded-md text-lg md:text-2xl cursor-pointer text-white font-semibold hover:bg-red-300 transition ease-linear"
        >
          Subscribe
        </button>
      </div>
      <img
        src={logokompakt}
        alt=""
        className="w-full max-w-[300px] fill-neutral-50 mt-5"
      />
    </div>
  );
};

export default Newletter;
